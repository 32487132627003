import { useNavigate } from "react-router";
import AboutUsText from "./AboutUsText";

const AboutUsPage = () => {
  const navigate = useNavigate();
  const goToHome = async () => {
    // try {
    //   if (mainUnityPlayerRef != null && mainUnityPlayerRef.current != null) {
    //     await mainUnityPlayerRef.current.QuitPlayer();
    //   }
    // } catch {
    //   console.log("failed to quit player in trydemopage");
    // }

    navigate("/s/hololoop_hululuup");

    // Send the form data to your server here to save it
    // and send the email to the user with the link to the demo.
  };

  return (
    <div
      style={{
        width: "96vw",
        height: "96vh",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div>
        <div style={{ background: "white", borderRadius: "10px" }}>
          <div style={{ margin: "20px" }}>
            <button className={"button"} style={{ marginTop: "20px", marginBottom: "20px" }} onClick={() => goToHome()}>
              Back
            </button>
            <AboutUsText />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
