import { useNavigate } from "react-router";
import AboutUsText from "./AboutUsText";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import toast from "react-hot-toast";
import { useQuery } from "@apollo/react-hooks";
import { GET_WORLD_ORDER } from "./queries";
import { GetWorldOrderData, GetWorldOrderRoot, WorldOrder } from "./Datatypes";

const PAYPAL_CLIENT_ID = () => {
  var envprocess = process.env;
  var envprocesslo = process.env.local;
  if (process.env.REACT_APP_PAYPAL_SANDBOX_MODE == "true") {
    return process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT_ID;
  } else if (process.env.REACT_APP_PAYPAL_SANDBOX_MODE == "false") {
    return process.env.REACT_APP_PAYPAL_LIVE_CLIENT_ID;
  }
  return "";
};

const Tracks = () => {
  const { loading, error, data } = useQuery<GetWorldOrderData>(GET_WORLD_ORDER);

  if (loading) return null;

  if (error) return `Error! ${error.message}`;
  if (data && data.getWorldOrder != undefined && data.getWorldOrder.worldOrder != undefined) {
    const worldOrder = data.getWorldOrder.worldOrder;
    let name = worldOrder.buyerName;
    let amount = worldOrder.amount;
    let orderId = worldOrder.orderId;
    let orderData = worldOrder.orderData;
    let date = worldOrder.dateCreated;
  }
  const amount = data?.getWorldOrder.worldOrder;
  return <div>{JSON.stringify(data)}</div>;
};

const WorldOrderPaymentPage = (props: { checkOutCompleteAction?: Function }) => {
  const [paymentComplete, setPaymentComplete] = useState(false);
  const idParams = useParams();
  const [orderId, setOrderId] = useState("");
  const { loading, error, data } = useQuery<GetWorldOrderData>(GET_WORLD_ORDER, { variables: { orderId: orderId } });
  const [worldOrder, setWorldOrder] = useState<WorldOrder>();
  const path = window.location.href;

  console.log("idParamsTest:" + idParams);
  const checkoutCompleteAction = () => {};
  const PaymentCompleteAction = () => {
    setPaymentComplete(true);
  };
  // const orderId = idParams.id; //TODO: is it okay for order id to dup worldorderid?

  useEffect(() => {
    if (idParams.id != undefined) {
      setOrderId(idParams.id);
    }
  }, [idParams.id]);
  // const amount = "20.0"; // TODO: need to get the amount from the server
  useEffect(() => {
    if (data && data.getWorldOrder != undefined && data.getWorldOrder.worldOrder != undefined) {
      const worldOrder = data.getWorldOrder.worldOrder;
      setWorldOrder(worldOrder);
      // let name = worldOrder.buyerName;
      // let amount = worldOrder.amount;
      // let orderId = worldOrder.orderId;
      // let orderData = worldOrder.orderData;
      // let date = worldOrder.dateCreated;
    }
  }, [data]);

  if (loading) return null;
  if (worldOrder == undefined) return null;
  if (error) return null;
  if (paymentComplete == true)
    return (
      <div>
        <h3>Payment Complete. You can close this page</h3>
      </div>
    );
  if (idParams != undefined && idParams.id != undefined) {
    console.log("idParamsTest2:" + idParams.id);
    return (
      <div style={{ width: "600", height: "800" }}>
        <div>
          <h3>Your making a purchase via a Hululuup Game World</h3>
          <h3>The game world is {worldOrder.world.worldName}</h3>
          <h3>The game world id is {worldOrder.world.worldId}</h3>
          <h3>The Cart date is {worldOrder.dateCreated}</h3>
          <h3>The total cost is {worldOrder.amount}</h3>
          {/* <h3>The items are....</h3>
          <h3>{worldOrder.orderData}</h3> */}
          <h3>Please pay now to receive your order</h3>
        </div>

        <PayPalScriptProvider options={{ "client-id": PAYPAL_CLIENT_ID() }}>
          {/* <div
                      style={{ position: "absolute", left: "0", right: "0", top: "0", bottom: "0", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 100 }}
                    ></div> */}
          <div style={{ zIndex: 110 }}>
            <PayPalButtons
              style={{ layout: "horizontal" }}
              onError={(err) => {
                toast.error("There was an error processing your payment. If this error please contact support.", { duration: 6000 });
              }}
              onApprove={(data, actions) => {
                if (checkoutCompleteAction != null) {
                  try {
                    checkoutCompleteAction();
                  } catch (e) {
                    console.log("failed checkout complete action");
                  }
                }
                return actions?.order?.capture().then(function (details) {
                  toast.success("Payment completed. Thank you, " + details?.payer?.name?.given_name);
                  PaymentCompleteAction();
                }) as any;
              }}
              onCancel={() =>
                toast("You cancelled the payment. Try again by clicking the PayPal button", {
                  duration: 6000,
                })
              }
              createOrder={(data, actions) => {
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        value: worldOrder.amount.toString(),
                      },
                      custom_id: worldOrder?.orderId, // the name or slug of the thing you're selling
                    },
                  ],
                });
              }}
            />
          </div>
        </PayPalScriptProvider>
      </div>
    );
  } else {
    return <div style={{ width: "100vw", height: "97vh", display: "flex", flexDirection: "column" }}>Path Incorrect</div>;
  }
};

export default WorldOrderPaymentPage;
