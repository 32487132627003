export function MustUsePCPage() {
  return (
    <div>
      <h3 style={{ color: "black", marginBottom: "10px" }}>Must use this site on a Desktop or Laptop, PC or MAC.</h3>
      <h4 style={{ color: "black" }}>It's a 3D gaming Shopping Experience. So we want you to have the best experience possible</h4>
      <h4 style={{ color: "black" }}>In the future, we will support mobile platforms such as IOS and Android.</h4>
      <h4 style={{ color: "black" }}>If you believe this is an error, please e-mail support@hololoop.co</h4>
    </div>
  );
}
