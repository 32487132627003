import React from "react";
import styles from "./AboutUsText.module.css";
const AboutUsText = () => {
  return (
    <div className={styles.AboutUsText} style={{ display: "flex", flexDirection: "column", width: "50%", justifySelf: "center", alignSelf: "center" }}>
      <div>
        <h1>Terms of Service</h1>
        <p>
          Welcome to Hololoop Inc.! These Terms of Service ("Terms") govern your use of our website (www.hololoop.co) and any related services (collectively,
          the "Service"). By accessing or using our Service, you agree to be bound by these Terms.
        </p>

        <h2>1. Account Creation</h2>
        <p>
          To access certain features or make purchases on our website, you may need to create an account. You must provide accurate and complete information
          during the registration process. You are responsible for maintaining the confidentiality of your account and password and agree to accept
          responsibility for all activities that occur under your account.
        </p>

        <h2>2. Use of the Service</h2>
        <p>
          You may use the Service for personal, non-commercial purposes only. You agree not to use the Service for any illegal, unauthorized, or prohibited
          activities. We reserve the right to cancel or refuse membership at any time.
        </p>

        <h2>3. Purchases and Payment</h2>
        <p>
          Our website offers a selection of t-shirts, hats, and shoes for purchase. By placing an order, you agree to pay the specified price for the selected
          items. Please note that the actual product may have slight variances from the 3D representation on our website. We will do our best to deliver a
          product that closely resembles the one shown on the website. The product images are more accurate than what is shown on the character.
        </p>

        <h2>4. Custom Stores and Products for Merchants</h2>
        <p>
          Some Merchants will be given the service of creating custom stores and may have custom products. The likeness of these custom products or custom
          stores in 3D is not owned by the merchant, and they make no claims to that ownership. The only thing that is owned by the merchant are their real-life
          trademarks, trademarked branding, and real-life products.
        </p>

        <p>
          We strive to maintain the uniqueness of each merchant's customized store and products, and we respect their intellectual property rights. However,
          please note that we cannot guarantee that other items or stores won't have similar features or appearances. This is because our goal is to
          continuously expand the variety and selection of items for the metaverse.
        </p>

        <h2>5. Intellectual Property</h2>
        <p>
          The Service and all its content, including but not limited to text, graphics, logos, images, and software, are the property of Hololoop Inc. or its
          licensors and are protected by intellectual property laws. You agree not to reproduce, modify, distribute, or create derivative works based on our
          content without our prior written consent.
        </p>

        <h2>6. Limitation of Liability</h2>
        <p>
          To the extent permitted by law, Hololoop Inc. and its directors, employees, affiliates, and agents shall not be liable for any direct, indirect,
          incidental, consequential, or punitive damages arising out of or related to the use of the Service.
        </p>

        <h2>7. Governing Law and Jurisdiction</h2>
        <p>
          These Terms shall be governed by and construed in accordance with the laws of the State of Delaware, United States. Any legal actions or proceedings
          arising out of or related to these Terms shall be brought exclusively in the federal or state courts located in Delaware.
        </p>

        <h1>Privacy Policy</h1>
        <h2>1. Information Collection and Use</h2>
        <p>
          Hololoop Inc. collects and uses personal information for the purpose of providing and improving the Service. The types of information we may collect
          include but are not limited to:
        </p>

        <ul>
          <li>Name, email address, and contact details provided during account registration.</li>
          <li>Transaction information for purchases made on the website.</li>
          <li>Usage data, such as IP addresses, browser information, and device identifiers.</li>
        </ul>

        <p>
          We may use the collected information to communicate with you, process orders, personalize your experience, and provide customer support. We will not
          sell or disclose your personal information to third parties, except as required by law or with your consent.
        </p>

        <h2>2. Cookies</h2>
        <p>
          We may use cookies and similar tracking technologies to enhance your browsing experience and collect information about how you use our website. You
          can adjust your browser settings to refuse cookies or alert you when cookies are being sent, but this may affect certain features of the Service.
        </p>
        <h2>3. Data Security</h2>
        <p>
          Hololoop Inc. takes reasonable measures to protect the security and integrity of your personal information. However, please be aware that no method of
          transmission or storage over the internet is completely secure. We cannot guarantee the absolute security of your data.
        </p>

        <h2>4. Third-Party Services</h2>
        <p>
          Our Service may contain links to third-party websites or services that are not operated or controlled by Hololoop Inc. We are not responsible for the
          privacy practices or content of these third-party sites. We encourage you to review the privacy policies of those websites before providing any
          personal information.
        </p>

        <h2>5. Children's Privacy</h2>
        <p>
          Our Service is not intended for individuals under the age of 18. We do not knowingly collect personal information from children. If you believe that
          we have inadvertently collected personal information from a child, please contact us immediately, and we will take appropriate steps to remove the
          information from our systems.
        </p>

        <h2>6. Changes to the Policy</h2>
        <p>
          Hololoop Inc. reserves the right to modify or update this Privacy Policy at any time. Any changes will be effective upon posting the revised policy on
          our website. It is your responsibility to review the Privacy Policy periodically for any updates.
        </p>

        <h2>Affiliate Program</h2>
        <p>
          We plan to offer affiliate links to individuals who refer new merchants to us. Our affiliate deals are customized for each affiliate, but generally,
          we hope to provide a 10% commission on the sign-up fee and a 10% commission on our share of the merchant's revenue for the first six months of the
          merchant's sales. This arrangement is subject to change at our discretion.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions, concerns, or feedback regarding these Terms of Service or Privacy Policy, please contact us at{" "}
          <a href="mailto:support@hololoop.co">support@hololoop.co</a> or write to us at:
        </p>

        <address>
          Hololoop Inc. <br />
          8 The Grn Ste 7755, Dover, Delaware 19901 <br />
          United States
        </address>
      </div>
    </div>
  );
};
export default AboutUsText;
