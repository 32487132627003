import React, { useEffect, useState } from "react";
// import logo from "./logo.svg";
import "./App.css";
import MainUnityPlayer from "./MainUnityPlayer";

import { BrowserRouter as Router, Route, Routes, useParams } from "react-router-dom";
import ShopifyCheckout from "./ShopifyCheckout";
// import LandingPage from "./onboarding/LandingPageBkp";

import { ApolloClient, InMemoryCache } from "@apollo/client";
import { ApolloProvider } from "@apollo/react-hooks";
// import LandingPageTest from "./onboarding/LandingPageTest";
// import PricingPage from "./onboarding/PricingPlanPage";
import LandingPage from "./onboarding/LandingPage";
import { PlayerHeader } from "./PlayerHeader";
import AboutUsPage from "./AboutUsPage";
import CustomerIntroPopup from "./CustomerIntroPopup";
import LandingPageVideo from "./onboarding/LandingPageVideo";
import WorldOrderPaymentPage from "./WorldOrderPaymentPage";
import MainUnityPlayerMobileTest from "./MainUnityPlayerMobileTest";
import InfoPage from "./onboarding/InfoPage";
import DeckView from "./onboarding/DeckView";
import { MustUsePCPage } from "./onboarding/MustUsePCPage";

const get_graphql_endpoint = () => {
  // var envprocess = process.env;
  // var envprocesslo = process.env.local;
  if (process.env.REACT_APP_LIVE_GRAPHQL_MODE == "true") {
    return process.env.REACT_APP_GRAPHQL_LIVE_ENDPOINT;
  } else if (process.env.REACT_APP_LIVE_GRAPHQL_MODE == "false") {
    return process.env.REACT_APP_GRAPHQL_DEV_ENDPOINT;
  }
  return "";
};
const client = new ApolloClient({
  uri: get_graphql_endpoint(),
  cache: new InMemoryCache(),
});

function MainContainerMTest() {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    try {
      const path = window.location.href;
      const deviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? "Mobile" : "Desktop";
      if (deviceType != "Mobile" || path.includes("skip_desktop_check")) {
        setIsDesktop(true);
      }
    } catch (e) {
      console.log("ft");
    }
  }, []);

  return (
    <div style={{ width: "100vw", height: "97vh" }}>
      <div style={{ height: "5%" }}>
        <PlayerHeader />
      </div>
      <div style={{ height: "95%" }}>
        <MainUnityPlayerMobileTest enableFullScreen={true} />
      </div>
    </div>
  );
}

function MainContainter() {
  const path = window.location.href;
  const idParams = useParams();
  const [customerIntroPopupOpen, setCustomerIntroPopupOpen] = useState(true);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    try {
      const path = window.location.href;
      const deviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? "Mobile" : "Desktop";
      if (deviceType != "Mobile" || path.includes("skip_desktop_check")) {
        setIsDesktop(true);
      }
    } catch (e) {
      console.log("ft");
    }
  }, []);
  let rndMode = false;
  if (path.includes("rnd=true")) {
    rndMode = true;
  }
  if (isDesktop == false) {
    return <MustUsePCPage />;
  }
  console.log("idParamsTest:" + idParams);
  if (idParams != undefined && idParams.id != undefined) {
    console.log("idParamsTest2:" + idParams.id);
    return (
      <div style={{ width: "100vw", height: "97vh" }}>
        {customerIntroPopupOpen ? <CustomerIntroPopup closePopup={() => setCustomerIntroPopupOpen(false)} /> : null}

        <div style={{ height: "5%" }}>
          <PlayerHeader />
        </div>
        <div style={{ height: "95%" }}>
          <MainUnityPlayer path={idParams.id} rnd={rndMode} />
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ width: "100vw", height: "97vh", display: "flex", flexDirection: "column" }}>
        {customerIntroPopupOpen ? <CustomerIntroPopup closePopup={() => setCustomerIntroPopupOpen(false)} /> : null}
        <div style={{ height: "5%" }}>
          <PlayerHeader />
        </div>
        <div style={{ height: "95%" }}>
          <MainUnityPlayer path={undefined} rnd={rndMode} />
        </div>
      </div>
    );
  }
}

// function App() {
//   if ((document as any).getElementById("context") != null) {
//     const context = JSON.parse((document as any).getElementById("context").textContent);
//   }

//   return <PricingPage />;
// }

function App() {
  if ((document as any).getElementById("context") != null) {
    const context = JSON.parse((document as any).getElementById("context").textContent);
  }

  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    try {
      const path = window.location.href;
      const deviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? "Mobile" : "Desktop";
      if (deviceType != "Mobile" || path.includes("skip_desktop_check")) {
        setIsDesktop(true);
      }
    } catch (e) {
      console.log("ft");
    }
  }, []);
  return (
    <ApolloProvider client={client}>
      <Router>
        <Routes>
          <Route path="/intro/*" element={<LandingPage />} />
          <Route path="/corp/deck/hlsqwd/" element={<DeckView />} />
          <Route path="/corp/deck/:id" element={<DeckView />} />
          <Route path="/corp/more/:id" element={<InfoPage />} />
          <Route path="/info/*" element={<InfoPage />} />
          {/* <Route path="/intro_video/*" element={<LandingPageVideo />} /> */}
          <Route path="/s/:id?" element={<MainContainter />} />
          <Route path="/m/:id?" element={<MainContainerMTest />} />
          <Route path="/world_orders/:id?" element={<WorldOrderPaymentPage />} />
          <Route path="about_us/" element={<AboutUsPage />} />
          <Route path="" element={<MainContainter />} />
        </Routes>
      </Router>
    </ApolloProvider>
  );
}

// function App() {
//   const routes = [{ path: "/members/:id" }];
//   const useCurrentPath = () => {
//     const location = useLocation();
//     const route = matchRoutes(routes, location);
//     console.log("route:" + route);
//     return route;
//   };

//   const currentPath = useCurrentPath();
//   return(<Router></Router>)
//   return <MainUnityPlayer />;
// }

// function App() {
//   return (
//     <div className="App">
//       {/* <header className="App-header" /> */}
//       {/* <img src={logo} alt="react logo" style={{ width: "400px" }} /> */}
//       <MainUnityPlayer />
//     </div>
//   );
// }

export default App;
