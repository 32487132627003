import { useMutation } from "@apollo/react-hooks";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DemoIntroPopup from "../DemoIntroPopup";
import MainUnityPlayer from "../MainUnityPlayer";
import { REGISTER_TRACKING_MUTATION } from "./queries";
import styles from "./TryDemoPage.module.css";

function MustUsePCPage() {
  return (
    <div>
      <h3 style={{ color: "black", marginBottom: "10px" }}>Must use this site on a Desktop or Laptop, PC or MAC.</h3>
      <h4 style={{ color: "black" }}>It's a 3D gaming Shopping Experience. So we want you to have the best experience possible</h4>
      <h4 style={{ color: "black" }}>In the future, we will support mobile platforms such as IOS and Android.</h4>
      <h4 style={{ color: "black" }}>If you believe this is an error, please e-mail support@hololoop.co</h4>
    </div>
  );
}

const TryDemoPage = () => {
  const [demoIntroPopupOpen, setDemoIntroPopupOpen] = useState(true);
  const [isDesktop, setIsDesktop] = useState(false);
  // TRACKING
  const [registerTrackingMutation, { data: registerTrackingMutationData }] = useMutation(REGISTER_TRACKING_MUTATION);
  useEffect(() => {
    try {
      const path = window.location.href;
      const deviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? "Mobile" : "Desktop";
      registerTrackingMutation({ variables: { path: path, deviceType: deviceType, eventCode: "pageVisit" } });
    } catch (e) {
      console.log("ft");
    }
  }, [registerTrackingMutation]);
  // TRACKING

  useEffect(() => {
    try {
      const path = window.location.href;
      const deviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? "Mobile" : "Desktop";
      if (deviceType != "Mobile" || path.includes("skip_desktop_check")) {
        setIsDesktop(true);
      }
    } catch (e) {
      console.log("ft");
    }
  }, []);

  //   const mainUnityPlayerRef = useRef<any>(null);
  const navigate = useNavigate();
  const demoPath = "storedemo";
  //   return <MainUnityPlayer path={demoPath} />;
  const handleClickCreateMyStore = () => {
    goToSignupPage();
  };

  const goToSignupPage = async () => {
    // try {
    //   if (mainUnityPlayerRef != null && mainUnityPlayerRef.current != null) {
    //     await mainUnityPlayerRef.current.QuitPlayer();
    //   }
    // } catch {
    //   console.log("failed to quit player in trydemopage");
    // }

    navigate("/intro/signup");

    // Send the form data to your server here to save it
    // and send the email to the user with the link to the demo.
  };

  return (
    <div>
      {isDesktop == true ? (
        <div style={{ justifyContent: "center", alignItems: "center", textAlign: "center", padding: "10px", width: "100vw", height: "100vh" }}>
          {demoIntroPopupOpen ? <DemoIntroPopup closePopup={() => setDemoIntroPopupOpen(false)} /> : null}
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
            <h2 style={{ marginRight: "10px", alignSelf: "center", color: "black" }}>Hololoop Shops Tryout!</h2>
            <h2>➡️</h2>
            <button className={styles["dancing-button"]} style={{ alignSelf: "center" }} onClick={handleClickCreateMyStore}>
              Create My Store!!
            </button>
          </div>
          <h3 style={{ marginRight: "10px", alignSelf: "center", color: "black" }}>Try the Customer experience. </h3>
          <h3 style={{ marginRight: "10px", alignSelf: "center", color: "black" }}>
            When you are Done! Press ( Esc ) to see the mouse cursor, and click ( Create My Store )
          </h3>
          <MainUnityPlayer path={undefined} enableFullScreen={false} />
        </div>
      ) : (
        <MustUsePCPage />
      )}
    </div>
  );
};

export default TryDemoPage;
