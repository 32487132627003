import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import styles from "./LandingPage.module.css";
import { REGISTER_TRACKING_MUTATION, SEND_OPT_IN_EMAIL_FOR_DEMO } from "./queries";

import Cookies from "js-cookie";
import { Link, Route, Routes, useNavigate, useParams } from "react-router-dom";
import TryDemoPage from "./TryDemoPage";
import SignUpPage from "./SignUpPage";
import SignUpCompletePage from "./SignUpCompletePage";
import PricingPlanPage from "./PricingPlanPage";
import MainUnityPlayer from "../MainUnityPlayer";
import DemoIntroPopup from "../DemoIntroPopup";
import LandingPageVideo from "./LandingPageVideo";
// import { PlayerHeaderIntro } from "../PlayerHeaderIntro";
import { MustUsePCPage } from "./MustUsePCPage";
import CustomerIntroPopup from "../CustomerIntroPopup";
import ShareButton from "../Sharing";
import AboutUsText from "../AboutUsText";
// // Access cookie by name
// const referral_code = Cookies.get("referral_code");

// // Access all cookies as an object
// const allCookies = Cookies.get();

// const sendEmailToServerForOptIn = (email: string) => {};
const MailtoLink = ({ email, subject, body }: any) => {
  const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

  return (
    <Link to={mailtoUrl} className={styles.cta}>
      Contact Us
    </Link>
  );
};

const AboutUs = (props: { closeAboutUs: any }) => {
  return (
    <div
      style={{
        zIndex: "100",
        position: "absolute",
        left: "10px",
        right: "10px",
        top: "10px",
        bottom: "10px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div>
        <div style={{ background: "white", borderRadius: "10px" }}>
          <div style={{ margin: "20px" }}>
            <button className={styles.button} style={{ marginTop: "20px", marginBottom: "20px" }} onClick={() => props.closeAboutUs()}>
              CLOSE
            </button>
            <AboutUsText />
          </div>
        </div>
      </div>
    </div>
  );
};

function PlayerHeaderIntro(props: { handleQualifyButtonClick: any }) {
  const [displayAboutUs, setDisplayAboutUs] = useState(false);
  const HandleDisplayAboutUs = () => {
    setDisplayAboutUs(true);
  };

  const closeAboutUs = () => {
    setDisplayAboutUs(false);
  };
  return (
    // <div style={{ display: "flex", flexDirection: "row", height: "30px", justifyContent: "flex-start" }}>
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "20px", marginRight: "20px" }}>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "30vw", alignItems: "center", textAlign: "center" }}>
        <div style={{ color: "black" }}>*Hint* Press Esc to see mouse</div>
        <ShareButton copyText={"https://shops.hololoop.co/s/hololoop_hululuup"} />
      </div>
      <button className={styles["submit-email"]} onClick={(e) => props.handleQualifyButtonClick(e)}>
        CREATE MY STORE
      </button>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", width: "30vw", alignItems: "center", textAlign: "center" }}>
        <h4 onClick={HandleDisplayAboutUs}>About Us Terms Of Service Privacy Policy</h4>

        <Link style={{ textDecoration: "none", color: "black" }} to={"/intro/video"}>
          <h4>More Info</h4>
        </Link>

        <MailtoLink email="support@hololoop.co" subject="Customer Help - Hololoop Store" body="I need Support">
          Contact Us
        </MailtoLink>
      </div>
      {displayAboutUs ? <AboutUs closeAboutUs={closeAboutUs} /> : null}
    </div>
  );
}

const LandingPage = () => {
  return (
    <Routes>
      <Route path="/video*" element={<LandingPageVideo />} />
      <Route path="/try_demo" element={<TryDemoPage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/signup_select_plan" element={<PricingPlanPage />} />
      <Route path="/signup_complete" element={<SignUpCompletePage />} />
      <Route path="*" element={<LandingPageIntro />} />
    </Routes>
  );
};

const LandingPageIntro = () => {
  const [customerIntroPopupOpen, setCustomerIntroPopupOpen] = useState(true);

  // const [demoIntroPopupOpen, setDemoIntroPopupOpen] = useState(true);

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [visitors, setVisitors] = useState("");
  const [revenue, setRevenue] = useState("");
  const [budget, setBudget] = useState("");
  const [websiteCost, setWebsiteCost] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [itemsSold, setItemsSold] = useState("");
  const [showQualifyForm, setShowQualifyForm] = useState(false);

  const [sendEmailToServerForOptIn, { data: sendEmailData }] = useMutation(SEND_OPT_IN_EMAIL_FOR_DEMO);

  // TRACKING
  const [registerTrackingMutation, { data: registerTrackingMutationData }] = useMutation(REGISTER_TRACKING_MUTATION);

  // const registerTrackingDataFunction = () => {
  //   try {
  //     const path = window.location.href;
  //     const deviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? "Mobile" : "Desktop";
  //     registerTrackingMutation({ variables: { path: path, deviceType: deviceType, eventCode: "submitEmail", data: JSON.stringify({ email: email }) } });
  //   } catch (e) {
  //     console.log("fte");
  //   }
  // };
  let rndMode = false;
  useEffect(() => {
    try {
      const path = window.location.href;

      const deviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? "Mobile" : "Desktop";
      registerTrackingMutation({ variables: { path: path, deviceType: deviceType, eventCode: "pageVisit" } });
    } catch (e) {
      console.log("ft");
    }
  }, [registerTrackingMutation]);
  // TRACKING
  useEffect(() => {
    if (sendEmailData && sendEmailData.sendEmailToServerForOptIn && sendEmailData.sendEmailToServerForOptIn.success == true) {
      console.log("email sent to server");
    }
  }, [sendEmailData]);

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handleVisitorsChange = (event: any) => {
    setVisitors(event.target.value);
  };

  const handleRevenueChange = (event: any) => {
    setRevenue(event.target.value);
  };

  const handleBudgetChange = (event: any) => {
    setBudget(event.target.value);
  };

  const handleWebsiteCostChange = (event: any) => {
    setWebsiteCost(event.target.value);
  };

  const handleWebsiteUrlChange = (event: any) => {
    setWebsiteUrl(event.target.value);
  };

  const handleItemsSoldChange = (event: any) => {
    setItemsSold(event.target.value);
  };

  const goToTryDemo = () => {
    navigate("/intro/try_demo");
    // todo: save email in context
    // Send the form data to your server here to save it
    // and send the email to the user with the link to the demo.
  };

  const goToSignupPage = async () => {
    // try {
    //   if (mainUnityPlayerRef != null && mainUnityPlayerRef.current != null) {
    //     await mainUnityPlayerRef.current.QuitPlayer();
    //   }
    // } catch {
    //   console.log("failed to quit player in trydemopage");
    // }

    navigate("/intro/signup");

    // Send the form data to your server here to save it
    // and send the email to the user with the link to the demo.
  };

  const handleQualifyButtonClick = (event: any) => {
    event.preventDefault();
    // if (email == "" || email == undefined) {
    //   window.alert(["email is required"]);
    // } else {
    // Access cookie by name
    const referral_code = Cookies.get("referral_code");

    // Access all cookies as an object
    const allCookies = Cookies.get();

    let referral_code_to_send = "";
    if (referral_code != undefined && referral_code != "") {
      referral_code_to_send = referral_code;
    }
    // sendEmailToServerForOptIn({ variables: { email: email, referralCode: referral_code_to_send } });
    // registerTrackingDatsaFunction();
    goToSignupPage();
    // }

    // setShowQualifyForm(true);
  };

  return (
    <div>
      <div style={{ width: "100vw", height: "97vh", display: "flex", flexDirection: "column" }}>
        {customerIntroPopupOpen ? <CustomerIntroPopup closePopup={() => setCustomerIntroPopupOpen(false)} /> : null}
        <div style={{ height: "5%" }}>
          <PlayerHeaderIntro handleQualifyButtonClick={handleQualifyButtonClick} />
        </div>
        <div style={{ height: "95%" }}>
          <MainUnityPlayer path={undefined} rnd={rndMode} />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
