import React from "react";
// import "./AboutUsText.css";

const CustomerIntroTextOld = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", justifySelf: "center", alignSelf: "center", margin: "10px" }}>
      <div>
        <h1 style={{ marginBottom: "10px" }}>Welcome</h1>
        <p style={{ marginBottom: "20px" }}>Thanks for visiting our 3d store. Shop for items in our gaming Metaverse, and we'll ship the real item to you!</p>
        <p style={{ marginBottom: "20px" }}>Also, Invite some friends here to Shop with You</p>
        <h2 style={{ color: "black", marginBottom: "10px" }}>Help Us Out</h2>
        <p style={{ marginBottom: "20px" }}>
          Please buy something from our store. The more people buy, the more 3D stores, products and features we can offer.
        </p>
        <h2 style={{ color: "black", marginBottom: "10px" }}>Controls</h2>
        <p style={{ marginBottom: "20px" }}>
          <b>To Move around:</b> <br />
          WASD + Mouse <br />
        </p>
        <p style={{ marginBottom: "20px" }}>
          <b>To Buy an Item:</b> <br />
          Click an Item. Click Add to Cart. Press M. Checkout and add Shipping Info. A Paypal Checkout will popup. Pay. Enjoy. <br />
        </p>
        <p style={{ marginBottom: "20px" }}>
          <b>To Invite a Friend:</b> <br />
          Press Esc to access mouse. Click the Invite a Friend button. Copy the link into a Messenger App. Your friend will join you and you can shop together.{" "}
          <br />
        </p>
        <p style={{ marginBottom: "20px" }}>
          <b>To Chat with a Friend:</b> <br />
          Pressing Left Alt Toggles the Chat Menu on or Off. Then Click the name field, and select a name. Click Text Field. Type a message and press Enter.{" "}
          <br />
          When you are finished, remember to press Left Alt.
          <b />
        </p>
        {/* <h2 style={{ color: "black" }}>Controls</h2>
        <p style={{ marginBottom: "20px" }}>
          To Move around: <br />
          WASD + Mouse W = Forward, A = Left, S = Back, D = Right. Mouse is to look around. <br />
          Left Click on Items that are interesting to you. The Triangle will appear on the item that is your center of interest. <br />
          Press M to access the menu and Cart. Press Esc and move the mouse to access your mouse cursor. <br />
          To Buy an Item: <br />
          Click an Item. Click Add to Cart. Press M. Checkout and add Shipping Info. A Paypal Checkout will popup. Pay. Enjoy. <br />
          To Invite a Friend: <br />
          Press Esc to access mouse. Click the Invite a Friend button. Copy the link into a Messenger App. Your friend will join you and you can shop together.{" "}
          <br />
          To Chat with a Friend: <br />
          Pressing Left Alt Toggles the Chat Menu on or Off. Then Click the name field, and select a name. Click Text Field. Type a message and press Enter.{" "}
          <br />
          When you are finished, remember to press Left Alt.
          <b />
        </p> */}

        <h2 style={{ color: "black", marginBottom: "10px" }}>We Want More Merch Stores!!</h2>
        <p style={{ marginBottom: "20px" }}>
          If you know of a cool Influencer or Shop Owner that should have their store in 3D, please Invite them to our platform. We can give you an Affiliate
          link and we can share some commission with you for introducing new merchants to us. Just Contact Us and mention that you want to help Promote.
        </p>

        <h2 style={{ color: "black", marginBottom: "10px" }}>Contact Us</h2>
        <p style={{ marginBottom: "20px" }}>
          If you have any questions, please contact us at <a href="mailto:support@hololoop.co">support@hololoop.co</a> or write to us at:
        </p>
      </div>
    </div>
  );
};

const CustomerIntroText = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", justifySelf: "center", alignSelf: "center", margin: "10px" }}>
      <div>
        <h1 style={{ marginBottom: "10px" }}>Welcome</h1>
        <p style={{ marginBottom: "20px" }}>Thanks for visiting our 3d store. Shop for items in our gaming Metaverse, and we'll ship the real item to you!</p>
        <p style={{ marginBottom: "20px" }}>Also, Invite some friends here to Shop with You</p>

        <h2 style={{ color: "black", marginBottom: "10px" }}>Controls</h2>
        <p style={{ marginBottom: "20px" }}>
          <b>To Move around:</b> <br />
          <text style={{ fontWeight: "bold", color: "green" }}>WASD controls movement & Mouse looks around</text> <br />
        </p>
        <p style={{ marginBottom: "20px" }}>
          <b>To Buy an Item:</b> <br />
          Click an Item. Click Add to Cart. Press M to Checkout.
          <br />
        </p>

        <p style={{ marginBottom: "20px" }}>
          <b>To Chat with a Friend:</b> <br />
          Pressing Left Alt Toggles the Chat Menu on or Off. Then Click the name field, and select a name. Click Text Field. Type a message and press Enter.{" "}
          <br />
          When you are finished, remember to press Left Alt.
          <b />
        </p>
        {/* <h2 style={{ color: "black" }}>Controls</h2>
        <p style={{ marginBottom: "20px" }}>
          To Move around: <br />
          WASD + Mouse W = Forward, A = Left, S = Back, D = Right. Mouse is to look around. <br />
          Left Click on Items that are interesting to you. The Triangle will appear on the item that is your center of interest. <br />
          Press M to access the menu and Cart. Press Esc and move the mouse to access your mouse cursor. <br />
          To Buy an Item: <br />
          Click an Item. Click Add to Cart. Press M. Checkout and add Shipping Info. A Paypal Checkout will popup. Pay. Enjoy. <br />
          To Invite a Friend: <br />
          Press Esc to access mouse. Click the Invite a Friend button. Copy the link into a Messenger App. Your friend will join you and you can shop together.{" "}
          <br />
          To Chat with a Friend: <br />
          Pressing Left Alt Toggles the Chat Menu on or Off. Then Click the name field, and select a name. Click Text Field. Type a message and press Enter.{" "}
          <br />
          When you are finished, remember to press Left Alt.
          <b />
        </p> */}

        <h2 style={{ color: "black", marginBottom: "10px" }}>We Want More Merch Stores!!</h2>
        <p style={{ marginBottom: "20px" }}>
          If you know of a cool Influencer or Shop Owner that should have their store in 3D, please Invite them to our platform. We can give you an Affiliate
          link and we can share some commission with you for introducing new merchants to us. Just Contact Us and mention that you want to help Promote.
        </p>

        <h2 style={{ color: "black", marginBottom: "10px" }}>Contact Us</h2>
        <p style={{ marginBottom: "20px" }}>
          If you have any questions, please contact us at <a href="mailto:support@hololoop.co">support@hololoop.co</a> or write to us at:
        </p>
      </div>
    </div>
  );
};

const CustomerIntroPopup = (props: { closePopup: any }) => {
  return (
    <div
      style={{ position: "absolute", left: "5px", right: "5px", top: "5px", bottom: "5px", display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <div
        style={{
          width: "50%",
          minWidth: "800px",
          height: "85vh",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div>
          <div style={{ background: "white", borderRadius: "10px", justifyContent: "center", alignItems: "center" }}>
            <div style={{ margin: "20px", justifyContent: "center", alignItems: "center", flexDirection: "column", display: "flex" }}>
              <CustomerIntroText />
              <button className={"button"} style={{ marginBottom: "20px" }} onClick={() => props.closePopup()}>
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CustomerIntroPopup;
