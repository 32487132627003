import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import styles from "./LandingPage.module.css";
import { Link, Route, Routes } from "react-router-dom";
import { CardMedia, Paper, useMediaQuery } from "@mui/material";
import { REGISTER_TRACKING_MUTATION } from "./queries";

const InfoPage = () => {
  // TRACKING
  const [registerTrackingMutation, { data: registerTrackingMutationData }] = useMutation(REGISTER_TRACKING_MUTATION);
  useEffect(() => {
    try {
      const path = window.location.href;

      const deviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? "Mobile" : "Desktop";
      registerTrackingMutation({ variables: { path: path, deviceType: deviceType, eventCode: "infopage" } });
    } catch (e) {
      console.log("ft");
    }
  }, [registerTrackingMutation]);
  // TRACKING

  return (
    <Routes>
      <Route path="/" element={<InfoPageMain />} />
    </Routes>
  );
};

const InfoPageMain = () => {
  return (
    <div
      style={{
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className={styles["header"]}>
        <h1>Hololoop Shops Info and Demos</h1>
        <p>Here you can find more info, demo videos and interactive demos on this page</p>
      </div>
      <Paper style={{ flexDirection: "column", padding: "20px", margin: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div style={{ marginTop: "30px", width: "80%" }}>
          <h3>Current Investor Deck. </h3>
          <Link to="/corp/deck/hlsqwd/"> Investor Deck</Link>{" "}
        </div>
      </Paper>
      <Paper
        style={{ width: "90vw", flexDirection: "column", padding: "20px", margin: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <div style={{ marginTop: "30px", width: "80%" }}>
          <h3>Quick Summary of Hololoop Shops/ Game2Life. </h3>
          <p>Buy Real Products from within Video Games and 3D Worlds.</p>
          <p>This platform is a shopify for any 3D virtual world.</p>
          <p>1. We give merchants a 3D virtual web store. </p>
          <p>2. We make it easy for merchants to sell from within third party video games </p>
          <p>3. The platform is compatible with web browsers, Unity, XR devices such as quest,XReal Airs and later Apple vision Pro.</p>
        </div>
      </Paper>

      <Paper
        style={{ width: "90vw", flexDirection: "column", padding: "20px", margin: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <div style={{ marginTop: "30px", width: "80%" }}>
          <h3>WATCH a Game World Demo. </h3>
          <p>
            This is a video demo. Showing an Hololoop shops in an example mobile game. We can go into stores and make purchases. We can go into restaurants and
            order food. Even vending machines and Food Trucks can be monetizable
          </p>
        </div>
        <div style={{ width: "70vw", alignSelf: "center", justifySelf: "center", display: "flex", justifyContent: "center" }}>
          <CardMedia
            className={styles["youtube-container"]}
            component="iframe"
            src="https://www.youtube.com/embed/s6lnoU2Mmio"
            sx={{
              aspectRatio: "16/9",
            }}
            // style={{ width: "80vw" }}
          />
        </div>
      </Paper>
      <Paper style={{ flexDirection: "column", padding: "20px", margin: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div style={{ marginTop: "30px", width: "80%" }}>
          <h3>TRY a web store demo browser. </h3>
          <p>Click Below for an Interactive Demo of a merchant 3D virtual store. Works in a desktop web browser</p>
          <Link to="/intro"> Click Here for Example Merchant Intro Page </Link>{" "}
        </div>
      </Paper>

      <Paper style={{ flexDirection: "column", padding: "20px", margin: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div style={{ marginTop: "30px", width: "80%" }}>
          <h3>WATCH a Web Store Demo. </h3>
          <p>
            This is a video demo. Showing a virtual web store in a browser. Demos trying on clothing, multiplayer, Shopify Integration and store customization.
          </p>
          <div style={{ alignSelf: "center", justifySelf: "center", display: "flex", justifyContent: "center" }}>
            <CardMedia
              className={styles["youtube-container"]}
              component="iframe"
              src="https://www.youtube.com/embed/S_QFInMSJiU"
              sx={{
                aspectRatio: "16/9",
              }}
              // style={{ width: "80vw" }}
            />
          </div>
        </div>
      </Paper>

      <Paper style={{ flexDirection: "column", padding: "20px", margin: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {/* <div style={{ marginTop: "30px", width: "80%" }}>
          <h3>TRY a Game World Demo in a mobile browser. </h3>
          <p>It's not an optimized version....Slow load, just for preview purposes. Needs testing on various devices.</p>
          <p>The merch store in this demo is for our VR game called Hululuup. It's on the Quest store. Game devs can even sell their own items as well.</p>
          <Link to="/m/mobiletest"> Click Here for Example Interactive Mobile Web Demo</Link>{" "}
        </div> */}
        <div style={{ marginTop: "30px", width: "100%" }}>
          <p>Interactive Demos for XReal and Oculus Quest coming soon.....</p>
        </div>
      </Paper>

      <Paper style={{ flexDirection: "column", padding: "20px", margin: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div style={{ marginTop: "30px", width: "80%" }}>
          <h3>WATCH a VR Rnd Demo. </h3>
          <p>
            This is a video VR Rnd demo. Experimenting with VR shopping experiences. We believe XR is the future of online retail. Later, real avatars can be
            visualized in a mirror. We can pick up and try on items. Perhaps even have a VR digital closet for collecting clothing overtime. XReal Air glasses
            also supported.
          </p>
          <div style={{ alignSelf: "center", justifySelf: "center", display: "flex", justifyContent: "center" }}>
            <CardMedia
              className={styles["youtube-container"]}
              component="iframe"
              src="https://www.youtube.com/embed/7lN2EORgg1k"
              sx={{
                aspectRatio: "16/9",
              }}
              // style={{ width: "80vw" }}
            />
          </div>
        </div>
      </Paper>

      <Paper>
        <div style={{ marginTop: "30px", marginBottom: "30px", width: "100%", padding: "10px" }}>
          <h3>Email Us if you have any questions</h3>
          <Link to="mailto:jermaine@hololoop.co"> jermaine@hololoop.co</Link>{" "}
        </div>
      </Paper>
    </div>
  );
};

export default InfoPage;
