import Client from "shopify-buy";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { ShoppingCartDataObject } from "./StoreDatatypes";

const CreateCheckoutProcess = async (shoppingCartData: ShoppingCartDataObject) => {
  //   let _shoppingCartData: ShoppingCartDataObject = JSON.parse(shoppingCartData);

  const client = Client.buildClient({
    // domain: "hololoop-dev.myshopify.com",
    domain: shoppingCartData.shopifyDomain,
    // storefrontAccessToken: "e777fa2c5a717e3bb550221b4a6ae9a7",
    storefrontAccessToken: shoppingCartData.shopifyStorefrontAccessToken,
    apiVersion: "2023-01",
  });

  // Initializing a client to return translated content
  const clientWithTranslatedContent = Client.buildClient({
    domain: shoppingCartData.shopifyDomain,
    storefrontAccessToken: shoppingCartData.shopifyStorefrontAccessToken,
    language: "en-US",
    apiVersion: "2023-01",
  });

  let products = await client.product.fetchAll();
  //   let skusToMatch = ["testsku"];
  //   let productsFound = [];
  let lineItemsToAdd = [];
  console.log(products);

  for (var product in products) {
    for (var variant in products[product].variants) {
      console.log("product sku:" + products[product].variants[variant].sku);
      let skuToMatch = products[product].variants[variant].sku;
      if (skuToMatch != undefined && skuToMatch != "") {
        for (var item in shoppingCartData.shoppingCartItems) {
          if (shoppingCartData.shoppingCartItems[item].product.sku == skuToMatch) {
            lineItemsToAdd.push({
              variantId: products[product].variants[variant].id,
              quantity: shoppingCartData.shoppingCartItems[item].quantity,
            });
          }
        }
        // if (skusToMatch.includes(skuToMatch)) {
        //   productsFound.push(products[product]);
        //   lineItemsToAdd.push({ variantId: products[product].variants[variant].id, quantity: 5 });
        //   // Checkou
        // }
      }
    }
  }

  // Create an empty checkout
  let checkout = await client.checkout.create();
  // Do something with the checkout
  //   console.log(checkout);

  // add the line items.
  let checkout2 = await client.checkout.addLineItems(checkout.id, lineItemsToAdd);
  //   console.log(checkout2.lineItems); // Array with one additional line item

  //   const shippingAddress = {
  //     address1: "Chestnut Street 92",
  //     address2: "Apartment 2",
  //     city: "Louisville",
  //     company: undefined,
  //     country: "United States",
  //     firstName: "Bob",
  //     lastName: "Norman",
  //     phone: "555-625-1199",
  //     province: "Kentucky",
  //     zip: "40202",
  //   };

  const shippingAddress = {
    address1: shoppingCartData.shippingInfo.address,
    address2: "",
    city: shoppingCartData.shippingInfo.city,
    company: undefined,
    country: shoppingCartData.shippingInfo.country,
    firstName: shoppingCartData.shippingInfo.name,
    lastName: shoppingCartData.shippingInfo.lastName,
    phone: shoppingCartData.shippingInfo.phoneNumber,
    province: shoppingCartData.shippingInfo.state,
    zip: shoppingCartData.shippingInfo.zip,
  };

  let checkout3 = await client.checkout.updateShippingAddress(checkout2.id, shippingAddress);
  var url = checkout3.webUrl;
  window.open(url, "_blank");
};

export default function ShopifyCheckout(props: { shoppingCartData: string }) {
  // Initializing a client to return content in the store's primary language
  let _shoppingCartData: ShoppingCartDataObject = JSON.parse(props.shoppingCartData);

  useEffect(() => {
    CreateCheckoutProcess(_shoppingCartData);
  }, [_shoppingCartData, props.shoppingCartData]);
  // // Build a custom products query using the unoptimized version of the SDK
  // const productsQuery = (client as any).graphQLClient.query((root) => {
  //     root.addConnection('products', {args: {first: 10}}, (product) => {
  //       product.add('title');
  //       product.add('tags');// Add fields to be returned
  //     });
  //   });

  //   // Call the send method with the custom products query
  //   client.graphQLClient.send(productsQuery).then(({model, data}) => {
  //     // Do something with the products
  //     console.log(model);
  //   });

  return <div>Shopify Test</div>;
}
