import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import styles from "./PlayerHeader.module.css";

export default function ShareButton({ copyText }: any) {
  const [isCopied, setIsCopied] = useState(false);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text: string) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        toast("Share link Copied! Paste the link to your friends in a Messenger App to Invite them Here!", {
          duration: 1500,
        });
        setIsCopied(true);

        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Toaster position="top-center" />
      {/* <input type="text" value={copyText} readOnly /> */}
      {/* Bind our handler function to the onClick button property */}
      <button className={styles["default-button"]} style={{ alignSelf: "center", justifySelf: "center" }} onClick={handleCopyClick}>
        <span>{isCopied ? "Share link Copied!" : "Invite Friends Here"}</span>
      </button>
    </div>
  );
}
