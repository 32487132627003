import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import styles from "./LandingPage.module.css";
import { REGISTER_TRACKING_MUTATION } from "./queries";
const DeckView = () => {
  // TRACKING
  const [registerTrackingMutation, { data: registerTrackingMutationData }] = useMutation(REGISTER_TRACKING_MUTATION);
  useEffect(() => {
    try {
      const path = window.location.href;
      const deviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? "Mobile" : "Desktop";
      registerTrackingMutation({ variables: { path: path, deviceType: deviceType, eventCode: "deckview" } });
    } catch (e) {
      console.log("ft");
    }
  }, [registerTrackingMutation]);
  // TRACKING

  return (
    <div style={{ width: "90vw", height: "90vh" }}>
      <div className={styles["responsive-google-slides"]}>
        <iframe
          title="Hololoop deck"
          src="https://docs.google.com/presentation/d/e/2PACX-1vS5lb9_4pT0LzKiT9mBo0JWvcp5lUv1t1SKdkQ9mbgwSXDfQ9JEnC_Ij94v5xhDP3qPySZtp4GdE1de/embed?start=false&loop=false&delayms=10000"
          allowFullScreen={true}
        ></iframe>
      </div>
    </div>
  );
};

export default DeckView;
// https://docs.google.com/presentation/d/e/xxxx/embed

//https://docs.google.com/presentation/d/e/1MgInzIZv-PxTPgCrSaZbEsBaHeaat92f6U9CsBHvwpo/embed

//https://docs.google.com/presentation/d/1MgInzIZv-PxTPgCrSaZbEsBaHeaat92f6U9CsBHvwpo
