import gql from "graphql-tag";

export const GET_WORLD_ORDER = gql`
  query getWorldOrder($orderId: String!) {
    getWorldOrder(orderId: $orderId) {
      worldOrder {
        world {
          worldId
          worldName
        }
        buyerName
        buyerEmail
        buyer {
          username
        }

        orderData
        amount
        orderStatus
        orderId
        dateCreated
      }
      success
      message
    }
  }
`;
