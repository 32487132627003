import React, { useEffect, useState } from "react";
import styles from "./PricingPageTest.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import PaypalSubscriptionButton, { OnApproveDataCustom } from "./PaypalSubscriptionButton";
import { REGISTER_TRACKING_MUTATION, SEND_MERCHANT_BILLING_AGREEMENT_ID_TO_SERVER } from "./queries";
import { useMutation } from "@apollo/react-hooks";
import { SendMerchantBillingAgreementIdToServerData } from "./DataTypes";
import { Link, useNavigate } from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const MailtoLink = ({ email, subject, body }: any) => {
  const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

  return (
    <Link to={mailtoUrl} className={styles.cta}>
      Contact Us
    </Link>
  );
};

const get_subscription_basic_id = () => {
  // var envprocess = process.env;
  // var envprocesslo = process.env.local;
  if (process.env.REACT_APP_PAYPAL_SANDBOX_MODE == "true") {
    return process.env.REACT_APP_PAYPAL_SANDBOX_BASIC_ID;
  } else if (process.env.REACT_APP_PAYPAL_SANDBOX_MODE == "false") {
    return process.env.REACT_APP_PAYPAL_LIVE_BASIC_ID;
  }
  return "";
};

const get_subscription_pro_id = () => {
  // var envprocess = process.env;
  // var envprocesslo = process.env.local;
  if (process.env.REACT_APP_PAYPAL_SANDBOX_MODE == "true") {
    return process.env.REACT_APP_PAYPAL_SANDBOX_PRO_ID;
  } else if (process.env.REACT_APP_PAYPAL_SANDBOX_MODE == "false") {
    return process.env.REACT_APP_PAYPAL_LIVE_PRO_ID;
  }
  return "";
};

const get_subscription_ultra_id = () => {
  // var envprocess = process.env;
  // var envprocesslo = process.env.local;
  if (process.env.REACT_APP_PAYPAL_SANDBOX_MODE == "true") {
    return process.env.REACT_APP_PAYPAL_SANDBOX_ULTRA_ID;
  } else if (process.env.REACT_APP_PAYPAL_SANDBOX_MODE == "false") {
    return process.env.REACT_APP_PAYPAL_LIVE_ULTRA_ID;
  }
  return "";
};

const get_subscription_enterprise_id = () => {
  // var envprocess = process.env;
  // var envprocesslo = process.env.local;
  if (process.env.REACT_APP_PAYPAL_SANDBOX_MODE == "true") {
    return process.env.REACT_APP_PAYPAL_SANDBOX_ENTERPRISE_ID;
  } else if (process.env.REACT_APP_PAYPAL_SANDBOX_MODE == "false") {
    return process.env.REACT_APP_PAYPAL_LIVE_ENTERPRISE_ID;
  }
  return "";
};

const getPayPalClientId = () => {
  var envprocess = process.env;
  var envprocesslo = process.env.local;
  if (process.env.REACT_APP_PAYPAL_SANDBOX_MODE == "true") {
    return process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT_ID;
  } else if (process.env.REACT_APP_PAYPAL_SANDBOX_MODE == "false") {
    return process.env.REACT_APP_PAYPAL_LIVE_CLIENT_ID;
  }
  return "";
};

const PricingPlanPage = () => {
  // TRACKING
  const [registerTrackingMutation, { data: registerTrackingMutationData }] = useMutation(REGISTER_TRACKING_MUTATION);
  useEffect(() => {
    try {
      const path = window.location.href;
      const deviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? "Mobile" : "Desktop";
      registerTrackingMutation({ variables: { path: path, deviceType: deviceType, eventCode: "pageVisit" } });
    } catch (e) {
      console.log("ft");
    }
  }, [registerTrackingMutation]);
  // TRACKING

  const [sendPaypalBillingIdToServer, { data: sendPaypalBillingIdToServerData }] = useMutation<SendMerchantBillingAgreementIdToServerData>(
    SEND_MERCHANT_BILLING_AGREEMENT_ID_TO_SERVER
  );
  const navigate = useNavigate();
  // const merchId = params.get("merchid");
  // const internalSubId = params.get("subid");
  const [clientId, setClientId] = useState("");
  const [subscriptionBasicId, setSubscriptionBasicId] = useState("");
  const [subscriptionProId, setSubscriptionProId] = useState("");
  const [subscriptionUltraId, setSubscriptionUltraId] = useState("");
  const [subscriptionEnterpriseId, setSubscriptionEnterpriseId] = useState("");

  useEffect(() => {
    setClientId(getPayPalClientId);
    setSubscriptionBasicId(get_subscription_basic_id);
    setSubscriptionProId(get_subscription_pro_id);
    setSubscriptionUltraId(get_subscription_ultra_id);
    setSubscriptionEnterpriseId(get_subscription_enterprise_id);
  }, []);

  useEffect(() => {
    console.log("before server result. ");
    if (
      sendPaypalBillingIdToServerData &&
      sendPaypalBillingIdToServerData.sendMerchantBillingAgreementIdToServer &&
      sendPaypalBillingIdToServerData.sendMerchantBillingAgreementIdToServer.success == true
    ) {
      console.log("payment proccessed by paypal and has been sent to server. ");
      // Navigate to payment page
      navigate("/intro/signup_complete");
    }
  }, [navigate, sendPaypalBillingIdToServerData]);

  const [merchId, setMerchId] = useState<string | undefined>(undefined);
  const [internalSubId, setInternalSubId] = useState<string | undefined>(undefined);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const merchId = params.get("merchid");
    const internalSubId = params.get("subid");
    if (merchId != null) {
      setMerchId(merchId);
    }
    if (internalSubId != null) {
      setInternalSubId(internalSubId);
    }
  }, []);

  const handleOnApprove = (data: OnApproveDataCustom) => {
    console.log("in handle on approve");
    sendPaypalBillingIdToServer({
      variables: {
        merchantId: merchId,
        paypalBillingAgreementId: data.subscriptionID,
      },
    });
  };

  const [dailyVisitors, setDailyVisitors] = useState(300);

  const handleSliderChange = (event: any) => {
    setDailyVisitors(event.target.value);
  };

  const recommendedPlan = () => {
    if (dailyVisitors >= 2000) {
      return "enterprise";
    } else if (dailyVisitors >= 1000) {
      return "ultra";
    } else if (dailyVisitors >= 300) {
      return "pro";
    } else {
      return "basic";
    }
  };

  const plans = [
    {
      planName: "basic",
      title: "Basic",
      price: "30",
      setupFee: 0,
      transactionFee: "5% per transaction.",
      inGameTransactionFee: "5% per transaction. *",
      features: ["Basic Store Template", "Up to 10 Products", "Email Support"],
      subscriptionId: subscriptionBasicId,
    },
    {
      planName: "pro",
      title: "Pro",
      price: "50",
      setupFee: 0,
      transactionFee: "3% per transaction.",
      inGameTransactionFee: "3% per transaction. *",
      features: ["Larger Store", "Up to 30 Products", "Multiplayer Customers", "Change Store Template", "Basic 3D Models", "Email Support"],
      subscriptionId: subscriptionProId,
    },
    {
      planName: "ultra",
      title: "Ultra",
      price: "100",
      setupFee: 0,
      transactionFee: "2% per transaction.",
      inGameTransactionFee: "2% per transaction. *",
      features: [
        "Larger Store",
        "Up to 100 Products",
        "Multiplayer Customers",
        "Change Store Template",
        "Advanced 3D Models",
        "Virtual Reality Support",
        "More Avatars",
        "Priority Phone Support",
      ],
      subscriptionId: subscriptionUltraId,
    },
    {
      planName: "enterprise",
      title: "Enterprise/Custom",
      price: "Custom",
      setupFee: "Starting at $1000",
      transactionFee: "2% per transaction.",
      features: [
        "Completely Custom Store Design",
        "Up to 1000 Products",
        "Multiplayer Customers",
        "3D Scanning Service",
        "Ad Management Services",
        "Virtual Reality Support",
        "Custom 3D Models",
        "Custom Features",
        "Custom Avatars",
        "Dedicated Account Manager",
      ],
      subscriptionId: subscriptionUltraId,
    },
  ];

  return (
    <>
      {clientId !== "" && subscriptionBasicId != "" && subscriptionProId != "" && subscriptionUltraId != "" && subscriptionEnterpriseId != "" ? (
        <PayPalScriptProvider
          options={{
            "client-id": clientId,
            intent: "subscription",
            vault: true,
            sdkBaseURL: "https://www.paypal.com/sdk/js",
            debug: true,
          }}
        >
          <>
            <div className={styles.header}>
              <h1>Hololoop Shops</h1>
              <p>
                Experience next-level online shopping with our 3D metaverse e-commerce platform, tailored for gaming-related apparel stores. Choose the plan
                that suits you best and enter the future of e-commerce today!
              </p>
            </div>
            <div className={styles.container}>
              <div className={styles["slider-container"]}>
                {dailyVisitors < 2000 ? (
                  <label htmlFor="visitor-slider">Daily Visitors: {dailyVisitors}</label>
                ) : (
                  <label htmlFor="visitor-slider">Daily Visitors: {dailyVisitors}+</label>
                )}

                <input
                  type="range"
                  id="visitor-slider"
                  name="visitor-slider"
                  min="50"
                  max="2000"
                  step="50"
                  value={dailyVisitors}
                  onChange={handleSliderChange}
                />
              </div>
              <div className={styles.pricing}>
                {plans.map((plan) => (
                  <div
                    key={plan.planName}
                    className={`${styles.card} ${plan.planName === recommendedPlan() ? styles.highlight : ""} ${
                      plan.planName === recommendedPlan() ? styles.recommended : ""
                    }`}
                  >
                    {plan.planName === recommendedPlan() && <div className={styles["recommended-header"]}>Recommended For You!</div>}
                    <h3>{plan.title}</h3>
                    <div className={styles.price}>
                      ${plan.price}
                      <span>/month</span>
                    </div>
                    {/* <div className={styles["setup-fee"]}>Setup Fee: ${plan.setupFee}</div> */}
                    <div className={styles["setup-fee"]}>{plan.transactionFee}</div>
                    <ul className={styles["feature-list"]}>
                      {plan.features.map((feature) => (
                        <li key={feature} className={styles["feature-item"]}>
                          <FontAwesomeIcon icon={faCheck} />
                          {feature}
                        </li>
                      ))}
                    </ul>
                    {merchId && internalSubId && plan.planName !== "enterprise" ? (
                      <div
                        style={{
                          display: "flex",
                          // backgroundColor: "red",
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <PaypalSubscriptionButton
                          subscriptionPlanID={plan.subscriptionId}
                          merchId={merchId}
                          internalSubId={internalSubId}
                          onApproveCallback={handleOnApprove}
                        />
                      </div>
                    ) : null}
                    {plan.planName === "enterprise" ? (
                      <MailtoLink
                        email="merchant_support@hololoop.co"
                        subject="New Merchant. Custom or Enterprise Support"
                        body="I need Custom/ Enterprise Support!"
                      >
                        Contact Us
                      </MailtoLink>
                    ) : (
                      <div style={{ backgroundColor: "red", width: "200px", justifySelf: "center", position: "relative" }}></div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div
              style={{
                borderRadius: "20px",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                color: "black",
                margin: "15px",
                marginTop: "20px",
                justifySelf: "center",
                padding: "20px",
              }}
            >
              <h2 style={{ marginBottom: "20px", color: "black" }}>About Fees...</h2>
              <h4>The Setup Fee includes us contacting you and helping you setup your store</h4>
              <h4>It is also a chance for you to ask us questions and make requests if needed</h4>
              <h4>The Setup Fee can be negotiated down... But it's not recommended because we'd rather help you setup your store..</h4>
              <h4>Our setup Fee is significantly cheaper than the cost to hire a website designer, let alone a 3D team to build a metaverse($300,000+)</h4>
              <h4>
                Our Monthly Fee is significantly cheaper than the cost to have a dedicated website designer, let alone a 3D team to maintain a
                metaverse($25,000+ per Month)
              </h4>
              <h4 style={{ marginTop: "30px", marginBottom: "30px" }}>a $50 a month Metaverse store is with a $200 Setup Fee is an amazing deal!</h4>

              <h4>Our team is giving you years of CG/Engineering experience bundled in low cost affordable packages.</h4>
              <h4 style={{ marginTop: "30px" }}>Later.... We will give the option to allow your store to appear in real video games.</h4>
              <h4>
                Currently. Video Games have fake buildings that are just textures. We plan to allow game creators to replace those buildings with your stores.
              </h4>
              <h4>If choose to have your store appear in a game, there will typically be a %4 fee that we credit to the game developer.</h4>
              <h4>
                Consider this nearly free advertisement, as players playing popular games and indie games will be able to walk into your virtual store front.
              </h4>
              <h4>This will increase sales.</h4>
            </div>
            <div
              style={{
                borderRadius: "20px",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                color: "black",
                margin: "15px",
                marginTop: "20px",
                justifySelf: "center",
                padding: "20px",
              }}
            >
              <h2 style={{ marginBottom: "20px", color: "black" }}>We Offer Addons and Additional Services</h2>
              <h4>Ad Management Services</h4>
              <h4>Custom Store Front Builds</h4>
              <h4>Scanning Real Brick and Morter Shops into Virtual 3D stores</h4>
              <h4>Physical Product to Metaverse Product Conversion</h4>
              <h4>Custom Feature Implimentation</h4>
              <h4>We are interested in working with Enterprise Clients to design new features and capibilities to expand our Shopping Metaverse such as....</h4>
              <h4>Furniture Stores that allow customer Avatars to lay down and sit down on Furniture</h4>
              <h4>Car Dealerships that want users to be able to sit in a car.</h4>
              <h4>Custom Influencer/Streamer/Celebrity Merch Stores.</h4>
              <h4>Other Interesting Experiences</h4>
              <h4>***We are especially interested in Gaming/VR/Metaverse related product stores ***</h4>
              <h4>Contact Us if you need something custom or have any questions</h4>
              <div style={{ marginTop: "20px" }}>
                <MailtoLink email="merchant_support@hololoop.co" subject="New Merchant. Custom or Enterprise Support" body="I need Custom/ Enterprise Support!">
                  Contact Us
                </MailtoLink>
              </div>
            </div>
          </>
        </PayPalScriptProvider>
      ) : null}
    </>
  );
};

export default PricingPlanPage;
