import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import styles from "./LandingPage.module.css";
import { REGISTER_TRACKING_MUTATION, SEND_OPT_IN_EMAIL_FOR_DEMO } from "./queries";

import Cookies from "js-cookie";
import { Route, Routes, useNavigate } from "react-router-dom";
import TryDemoPage from "./TryDemoPage";
import SignUpPage from "./SignUpPage";
import SignUpCompletePage from "./SignUpCompletePage";
import PricingPlanPage from "./PricingPlanPage";
import MainUnityPlayer from "../MainUnityPlayer";
import DemoIntroPopup from "../DemoIntroPopup";
// // Access cookie by name
// const referral_code = Cookies.get("referral_code");

// // Access all cookies as an object
// const allCookies = Cookies.get();

// const sendEmailToServerForOptIn = (email: string) => {};

const LandingPage = () => {
  return (
    <Routes>
      <Route path="/try_demo" element={<TryDemoPage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/signup_select_plan" element={<PricingPlanPage />} />
      <Route path="/signup_complete" element={<SignUpCompletePage />} />
      <Route path="*" element={<LandingPageIntro />} />
    </Routes>
  );
};

const LandingPageIntro = () => {
  const [demoIntroPopupOpen, setDemoIntroPopupOpen] = useState(true);

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [visitors, setVisitors] = useState("");
  const [revenue, setRevenue] = useState("");
  const [budget, setBudget] = useState("");
  const [websiteCost, setWebsiteCost] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [itemsSold, setItemsSold] = useState("");
  const [showQualifyForm, setShowQualifyForm] = useState(false);

  const [sendEmailToServerForOptIn, { data: sendEmailData }] = useMutation(SEND_OPT_IN_EMAIL_FOR_DEMO);

  // TRACKING
  const [registerTrackingMutation, { data: registerTrackingMutationData }] = useMutation(REGISTER_TRACKING_MUTATION);

  const registerTrackingDataFunction = () => {
    try {
      const path = window.location.href;

      const deviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? "Mobile" : "Desktop";
      registerTrackingMutation({ variables: { path: path, deviceType: deviceType, eventCode: "submitEmail", data: JSON.stringify({ email: email }) } });
    } catch (e) {
      console.log("fte");
    }
  };

  useEffect(() => {
    try {
      const path = window.location.href;
      const deviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? "Mobile" : "Desktop";
      registerTrackingMutation({ variables: { path: path, deviceType: deviceType, eventCode: "pageVisit" } });
    } catch (e) {
      console.log("ft");
    }
  }, [registerTrackingMutation]);
  // TRACKING
  useEffect(() => {
    if (sendEmailData && sendEmailData.sendEmailToServerForOptIn && sendEmailData.sendEmailToServerForOptIn.success == true) {
      console.log("email sent to server");
    }
  }, [sendEmailData]);

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handleVisitorsChange = (event: any) => {
    setVisitors(event.target.value);
  };

  const handleRevenueChange = (event: any) => {
    setRevenue(event.target.value);
  };

  const handleBudgetChange = (event: any) => {
    setBudget(event.target.value);
  };

  const handleWebsiteCostChange = (event: any) => {
    setWebsiteCost(event.target.value);
  };

  const handleWebsiteUrlChange = (event: any) => {
    setWebsiteUrl(event.target.value);
  };

  const handleItemsSoldChange = (event: any) => {
    setItemsSold(event.target.value);
  };

  const goToTryDemo = () => {
    navigate("/intro/try_demo");
    // todo: save email in context
    // Send the form data to your server here to save it
    // and send the email to the user with the link to the demo.
  };

  // const handleQualifyButtonClick = (event: any) => {
  //   event.preventDefault();
  //   if (email == "" || email == undefined) {
  //     window.alert(["email is required"]);
  //   } else {
  //     // Access cookie by name
  //     const referral_code = Cookies.get("referral_code");

  //     // Access all cookies as an object
  //     const allCookies = Cookies.get();

  //     let referral_code_to_send = "";
  //     if (referral_code != undefined && referral_code != "") {
  //       referral_code_to_send = referral_code;
  //     }
  //     sendEmailToServerForOptIn({ variables: { email: email, referralCode: referral_code_to_send } });
  //     registerTrackingDataFunction();
  //     goToTryDemo();
  //   }

  //   // setShowQualifyForm(true);
  // };

  const goToSignupPage = async () => {
    // try {
    //   if (mainUnityPlayerRef != null && mainUnityPlayerRef.current != null) {
    //     await mainUnityPlayerRef.current.QuitPlayer();
    //   }
    // } catch {
    //   console.log("failed to quit player in trydemopage");
    // }

    navigate("/intro/signup");

    // Send the form data to your server here to save it
    // and send the email to the user with the link to the demo.
  };

  const handleQualifyButtonClick = (event: any) => {
    event.preventDefault();
    // if (email == "" || email == undefined) {
    //   window.alert(["email is required"]);
    // } else {
    // Access cookie by name
    const referral_code = Cookies.get("referral_code");

    // Access all cookies as an object
    const allCookies = Cookies.get();

    let referral_code_to_send = "";
    if (referral_code != undefined && referral_code != "") {
      referral_code_to_send = referral_code;
    }
    // sendEmailToServerForOptIn({ variables: { email: email, referralCode: referral_code_to_send } });
    // registerTrackingDatsaFunction();
    goToSignupPage();
    // }

    // setShowQualifyForm(true);
  };

  return (
    <div style={{ width: "100vw" }}>
      <>
        <div className={styles["header"]}>
          <h1>Hololoop Shops</h1>
          <p>Discover the future of online shopping with the Hololoop Shops virtual 3D e-commerce platform. Try our online demo today!</p>
        </div>

        <div className={styles.container}>
          <form className={styles.form}>
            <div className={styles.sticky}>
              <h3 style={{ color: "black", textAlign: "center", justifyContent: "center", justifySelf: "center", alignSelf: "center" }}>
                Make Sales and Earn Money with Your Own virtual 3D Shop!{" "}
              </h3>
              <h2 style={{ fontSize: "2.5rem", textAlign: "center", justifyContent: "center", justifySelf: "center", alignSelf: "center" }}>➡️</h2>
              {/* <input type="email" placeholder="Enter your email to begin" value={email} onChange={handleEmailChange} required /> */}
              {/* <button className={styles["submit-email"]} onClick={(e) => handleQualifyButtonClick(e)}>
                TRY THE DEMO!!
              </button> */}
              <button className={styles["submit-email"]} onClick={(e) => handleQualifyButtonClick(e)}>
                CREATE MY STORE
              </button>
            </div>
          </form>
          <h3>Web browser store demo </h3>
          <div className={styles["demo-video"]}>
            <iframe title="Holoshop Product Demo" src="https://www.youtube.com/embed/S_QFInMSJiU?autoplay=1&mute=1" frameBorder="0" allowFullScreen></iframe>
          </div>
          <h3>Stores and restaurants also appear in third party video games</h3>
          <div className={styles["demo-video"]}>
            <iframe
              title="Holoshop In Game Demo"
              src="https://www.youtube.com/embed/s6lnoU2Mmio?start=35&autoplay=0&mute=1"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
          <h3>VR Shopping Demo. Optional Support for Quest, Apple VisionPro, XReal Airs and more</h3>
          <div className={styles["demo-video"]}>
            <iframe title="Holoshop In Game Demo" src="https://www.youtube.com/embed/7lN2EORgg1k?autoplay=0&mute=1" frameBorder="0" allowFullScreen></iframe>
          </div>
          {/* <div style={{ height: "660px" }}>
            {demoIntroPopupOpen ? <DemoIntroPopup closePopup={() => setDemoIntroPopupOpen(false)} /> : null}
            <MainUnityPlayer path={undefined} enableFullScreen={false} />
          </div> */}
          <div className={styles.section}>
            <h2>Key Features</h2>
            <p>
              With Hololoop Shops, you can create a unique and immersive shopping experience, allowing your customers to interact with your products like never
              before.
            </p>
            <p>Interactive 3D virtual store where customers can explore and shop</p>
            <p>Customer Avatars can try on clothing for a genuine shopping feel</p>
            <p>Multiple Customer Avatars can interact and chat together. This makes shopping fun, immersive and boosts engagement</p>
            <p>Increased sales and brand exposure because your store is a cutting edge 3d shop</p>
            <p>Integrates with Shopify Checkout</p>
            {/* <img src="/images/key_features.png" alt="Key Features" /> */}
          </div>
          <div className={styles.section}>
            <h2>Intuitive Interface</h2>
            <p>Our platform is designed to be easy to use and master, allowing you to focus on creating the best shopping experience for your customers.</p>
            {/* <img src="/images/intuitive_interface.jpg" alt="Intuitive Interface" /> */}
          </div>
          <form className={styles.form}>
            <div className={styles.sticky}>
              <h3 style={{ color: "black", textAlign: "center", justifyContent: "center", justifySelf: "center", alignSelf: "center" }}>
                Make Sales and Earn Money with Your Own virtual 3D Shop!{" "}
              </h3>
              <h2 style={{ fontSize: "2.5rem", textAlign: "center", justifyContent: "center", justifySelf: "center", alignSelf: "center" }}>➡️</h2>
              {/* <input type="email" placeholder="Enter your email to begin" value={email} onChange={handleEmailChange} required />
              <button className={styles["submit-email"]} onClick={(e) => handleQualifyButtonClick(e)}>
                TRY THE DEMO!!
              </button> */}
              <button className={styles["submit-email"]} onClick={(e) => handleQualifyButtonClick(e)}>
                CREATE MY STORE
              </button>
            </div>
          </form>
          <div className={styles.section}>
            <h2>Customizable Store Design</h2>
            <p>
              Hololoop Shops allows you complete control over your store's design, enabling you to create a shopping environment tailored to your brand and
              products.
            </p>
            <p>Easily Swap in and out different products and textures.</p>
            <p>Highly recommended for Print On Demand Shops. Simply Upload The Texture of your Apparel.</p>
            <p>Support for custom models</p>
            {/* <img src="/images/customizable_store_design.jpg" alt="Customizable Store Design" /> */}
          </div>
          <div className={styles.section}>
            <h2>Optional Addons Available</h2>
            <p>Custom Storefronts</p>
            <p>We can create Custom Storefronts</p>
            <p>Convert Real Life Brick and Morter Shops to 3D Virtual Stores</p>
            <p>Larger Storefronts</p>
            <p>Custom Features</p>
            {/* <img src="/images/customizable_store_design.jpg" alt="Customizable Store Design" /> */}
          </div>
          <form className={styles.form}>
            <div className={styles.sticky}>
              <h3 style={{ color: "black", textAlign: "center", justifyContent: "center", justifySelf: "center", alignSelf: "center" }}>
                Make Sales and Earn Money with Your Own virtual 3D Shop!{" "}
              </h3>
              <h2 style={{ fontSize: "2.5rem", textAlign: "center", justifyContent: "center", justifySelf: "center", alignSelf: "center" }}>➡️</h2>
              {/* <input type="email" placeholder="Enter your email to begin" value={email} onChange={handleEmailChange} required /> */}
              {/* <button className={styles["submit-email"]} onClick={(e) => handleQualifyButtonClick(e)}>
                TRY THE DEMO!!
              </button> */}
              <button className={styles["submit-email"]} onClick={(e) => handleQualifyButtonClick(e)}>
                CREATE MY STORE
              </button>
            </div>
          </form>
        </div>
      </>
    </div>
  );
};

export default LandingPage;
