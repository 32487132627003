import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import React, { Fragment, Ref, StyleHTMLAttributes, useCallback, useEffect, useRef, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { json } from "stream/consumers";
import CheckoutPopup from "./CheckoutPopup";
import Popup from "./popup/popupContext";
import { PopupProvider, usePopup } from "./popup/PopupProvider";
import PopupTrigger from "./popup/PopupTrigger";
import ShopifyCheckout from "./ShopifyCheckout";
import { ShoppingCartDataObject } from "./StoreDatatypes";
import Frame from "react-frame-component";
import Spinner from "./onboarding/Spinner";

import styles from "./MainUnityPlayer.module.css";
const buildName = "buildv0017";
// const buildNameM = "build.v0083m";
const buildNameRnd = "buildv0017vr";
const reactDevPath = "/unity_files/";
const tmpPath = "http://localhost:3000/static/unity_files/";
const prodPath = "https://hololoopinc-gametolife-mediastorage-public.s3.amazonaws.com/stores/basic/";
const basePath = prodPath;
const useProd = "true";
console.log("basePath:" + basePath);
let unityWebExt = "";
// if (useProd == "true") {
//   unityWebExt = ".unityweb";
// }

// const MainUnityPlayer = (props: { path: string | undefined; enableFullScreen?: boolean }) => {
//   return (
//     <div>
//       <Frame style={{ width: "100%", height: "100%" }}>
//         <MainUnityPlayerInner {...props} />
//       </Frame>
//     </div>
//   );
// };
function MainUnityPlayerMobileTest(props: { path?: string | undefined; enableFullScreen?: boolean; rnd?: boolean }) {
  // const unityContent = useRef<typeof Unity>(null);
  // const [path, setPath] = useState<string | undefined>("");

  // const [checkoutPopupOpen, setCheckoutPopupOpen] = useState(false);
  // const [shoppingCartData, setShoppingCartData] = useState("");

  const originalfetch = fetch;
  const { triggerPopup } = usePopup();
  function handleClickEnterFullscreen() {
    requestFullscreen(true);
  }

  function handleSendPurchaseStatus() {
    sendMessage("Common", "FromJS_ReceivePurchaseStatus", "status");
  }
  // useEffect(() => {
  //   return () => {
  //     if (unityContent.current) {
  //       unityContent.current.quitUnityInstance();
  //     }
  //   };
  // }, []);
  useEffect(() => {
    // setPath(props.path);
    console.log("build: " + buildName);
    console.log("the path:" + props.path);
  }, [props.path]);
  const {
    unityProvider,
    UNSAFE__detachAndUnloadImmediate: detachAndUnloadImmediate,
    loadingProgression,
    unload,
    isLoaded,
    sendMessage,
    requestFullscreen,
    UNSAFE__unityInstance,
    addEventListener,
    removeEventListener,
  } = useUnityContext({
    loaderUrl: `${basePath}${props.rnd == true ? buildNameRnd : buildName}/Build/${props.rnd == true ? buildNameRnd : buildName}.loader.js`,
    dataUrl: `${basePath}${props.rnd == true ? buildNameRnd : buildName}/Build/${props.rnd == true ? buildNameRnd : buildName}.data${unityWebExt}`,
    frameworkUrl: `${basePath}${props.rnd == true ? buildNameRnd : buildName}/Build/${props.rnd == true ? buildNameRnd : buildName}.framework.js${unityWebExt}`,
    codeUrl: `${basePath}${props.rnd == true ? buildNameRnd : buildName}/Build/${props.rnd == true ? buildNameRnd : buildName}.wasm${unityWebExt}`,
    // productName: "Game2Life",
    // productVersion: "1.0.0",
    // companyName: "Hololoop",
  });

  // useEffect(() => {
  //   async function unloadUnity() {
  //     // You can await here
  //     const response = await unload();
  //     // ...
  //   }
  //   return () => {
  //     try {
  //       unloadUnity();
  //     } catch {
  //       console.log("failed to unload unity");
  //     }
  //   };
  // }, [unload]);

  // useEffect(() => {
  //   if (UNSAFE__unityInstance != null) {
  //     // Lock the mouse cursor in the horizontal plane
  //     var canvas = UNSAFE__unityInstance.Module.canvas;
  //     canvas.addEventListener("mousemove", function (e: any) {
  //       var rect = canvas.getBoundingClientRect();
  //       var centerX = rect.left + rect.width / 2;
  //       var mouseY = e.clientY;
  //       var lockY = mouseY < rect.top || mouseY > rect.bottom;
  //       if (lockY) {
  //         var movementX = e.clientX - centerX;
  //         var movementY = 0; // Lock only in the horizontal plane
  //         UNSAFE__unityInstance.Module.SetPointerLock(true);
  //         UNSAFE__unityInstance.Module.MoveMouseLock(movementX, movementY);
  //       }
  //     });
  //   }
  // });
  useEffect(() => {
    // if (isLoaded) {
    //     initialLoad()
    // }
    const QuitPlayer = async () => {
      try {
        UNSAFE__unityInstance?.Quit();
      } catch {
        console.log("failed unityInstanceQuit");
      }
      try {
        detachAndUnloadImmediate();
      } catch {
        console.log("failed detachUnload");
      }
      try {
        await unload();
      } catch {
        console.log("failed unload");
      }
    };
    return () => {
      QuitPlayer();
      // detachAndUnloadImmediate();
    };
  }, [UNSAFE__unityInstance, detachAndUnloadImmediate, unload]);

  // const handleFromUnityCheckoutJsonData = useCallback(
  //   (checkoutJsonData: string) => {
  //     sendMessage("Common", "FromJs_SuspendInput");
  //     setCheckoutPopupOpen(true);
  //     setShoppingCartData(checkoutJsonData);
  //     console.log("checkout data:" + checkoutJsonData);
  //   },
  //   [sendMessage]
  // );

  // const handleFromUnityCheckoutShopifyJsonData = useCallback(
  //   (checkoutJsonData: string) => {
  //     sendMessage("Common", "FromJs_SuspendInput");

  //     setCheckoutPopupOpen(true);
  //     setShoppingCartData(checkoutJsonData);
  //     console.log("checkout data:" + checkoutJsonData);
  //   },
  //   [sendMessage]
  // );

  // useEffect(() => {
  //   if (isLoaded) {
  //     if (props.path != undefined && props.path != "") {
  //       sendMessage("Common", "FromJS_SetStorePath", props.path);
  //     }
  //     sendMessage("Common", "FromJS_SetEmbedded", "true");
  //     sendMessage("Common", "FromJS_SetUseProductionUrl", useProd);
  //   }
  // }, [isLoaded, props.path, sendMessage]);

  const handleFromUnityOpenMobileKeyboard = useCallback(() => {
    // open mobile keyboard
    const enteredName = prompt("Please enter your name");
    sendMessage("Common", "FromJs_CloseMobileKeyboard");
    console.log("open mobile keyboard:");
  }, [sendMessage]);

  const handleFromUnityCloseMobileKeyboard = useCallback(
    (text: string) => {
      sendMessage("Common", "FromJs_CloseMobileKeyboard");

      // close mobile keyboard
      console.log("checkout data:" + text);
    },
    [sendMessage]
  );

  useEffect(() => {
    window.gameInstance = UNSAFE__unityInstance;

    //
    //

    // return () => {
    //   if (window.gameInstance) {
    //     window.gameInstance.quitUnityInstance();
    //   }
    // };
  }, [UNSAFE__unityInstance]);

  // // HACKY FIX KEYBOARD INPUT
  function FixInputOnSubmit(event: { preventDefault: () => void } | null) {
    if (document != null && document.getElementById("fixInput") != null) {
      document?.getElementById("fixInput")?.blur();
    }
    if (event != null) event.preventDefault();
  }
  // //

  // useEffect(() => {
  //   addEventListener("FromUnityOpenMobileKeyboard", handleFromUnityOpenMobileKeyboard);
  //   return () => {
  //     removeEventListener("FromUnityOpenMobileKeyboard", handleFromUnityOpenMobileKeyboard);
  //   };
  // }, [addEventListener, removeEventListener, handleFromUnityOpenMobileKeyboard]);

  // useEffect(() => {
  //   addEventListener("CloseMobileKeyboard", handleFromUnityCloseMobileKeyboard);
  //   return () => {
  //     removeEventListener("CloseMobileKeyboard", handleFromUnityCloseMobileKeyboard);
  //   };
  // }, [addEventListener, removeEventListener, handleFromUnityCloseMobileKeyboard]);

  // const setCheckOutPopupOpenClicked = () => {
  //   console.log("clicked");
  //   setCheckoutPopupOpen(true);
  // };

  // const SendCheckoutCompleteToUnity = () => {
  //   console.log("sending checkout data back to unity");
  //   sendMessage("Common", "FromJs_SetCheckoutComplete");
  // };

  // const setCheckoutPopupClosedClicked = () => {
  //   setCheckoutPopupOpen(false);
  // };
  return (
    <div style={{ width: "100%", height: "100%" }} className={`${styles["unity-player-container"]} ${isLoaded === false ? styles.loading : ""}`}>
      {/* {checkoutPopupOpen ? (
        <CheckoutPopup
          shoppingCartData={shoppingCartData}
          closePopup={() => {
            setCheckoutPopupOpen(false);
            sendMessage("Common", "FromJs_EnableInput");
          }}
          checkOutCompleteAction={SendCheckoutCompleteToUnity}
        />
      ) : (
        ""
      )} */}

      <Fragment>
        {!isLoaded && <Spinner percentage={Math.round(loadingProgression * 100)} />}
        {/* {!isLoaded && <p>Loading Application... {Math.round(loadingProgression * 100)}%</p>} */}
        <Unity unityProvider={unityProvider} style={{ width: "60%", height: "60%", visibility: isLoaded ? "visible" : "hidden" }} />
        {props.enableFullScreen ? <button onClick={handleClickEnterFullscreen}>Enter Fullscreen</button> : null}
        {/* {UNSAFE__unityInstance != null ? (
          <form onSubmit={(e) => FixInputOnSubmit(e)} autoComplete="off" style={{ width: "0px", height: "0px", position: "absolute", top: "-9999px" }}>
            <input
              type="text"
              id="fixInput"
              onInput={UNSAFE__unityInstance.Module.ccall("FixInputUpdate", null)}
              onBlur={UNSAFE__unityInstance.Module.ccall("FixInputOnBlur", null)}
              style={{ fontSize: "42px" }}
            />
          </form>
        ) : null} */}
      </Fragment>

      {/* <button onClick={(e) => setCheckOutPopupOpenClicked()}>open popup</button> */}
    </div>
  );

  // return <Unity unityProvider={unityProvider} style={{ width: 1280, height: 720 }} />;
}
export default MainUnityPlayerMobileTest;
/* <form action="https://www.paypal.com/cgi-bin/webscr" method="post">
  <input type="hidden" name="cmd" value="_xclick">
  <input type="hidden" name="business" value="youremail@example.com">
  <input type="hidden" name="item_name" value="Item Name">
  <input type="hidden" name="amount" value="10.00">
  <input type="hidden" name="notify_url" value="https://www.example.com/paypal_ipn.php">
  <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynow_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
</form> */
