import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useMutation } from "@apollo/client";
import { REGISTER_TRACKING_MUTATION, SEND_PENDING_MERCHANT_TO_SERVER } from "./queries";
import { useNavigate } from "react-router";
import { SendPendingMerchantToServerData, SendPendingMerchantToServerRoot } from "./DataTypes";
import { Paper } from "@mui/material";
import styles from "./SignUpPage.module.css";
// import styles from "./LandingPage.module.css";
function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://shops.hololoop.co/">
        Hololoop Inc.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function SignUpPage() {
  const [signUpMode, setSignUpMode] = useState(true);

  useEffect(() => {
    if (!window.location.hash) {
      window.location.hash = Math.random().toString();
      window.location.reload();
    }
  }, []);

  return <React.Fragment>{signUpMode ? <SignUpDisplay setSignUpMode={setSignUpMode} /> : <SignInDisplay setSignUpMode={setSignUpMode} />}</React.Fragment>;
}
function SignInDisplay(props: { setSignUpMode: any }) {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus />
            <TextField margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" />
            <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link variant="body2" component="button" onClick={() => props.setSignUpMode(true)}>
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

function SignUpDisplay(props: { setSignUpMode: any; capturedEmail?: string; capturedPhone?: string; capturedUrl?: string }) {
  const [pendingSubId, setPendingSubId] = useState("");
  const [sendPendingMerchantToServer, { data: sendPendingMerchantData }] = useMutation<SendPendingMerchantToServerData>(SEND_PENDING_MERCHANT_TO_SERVER);
  const navigate = useNavigate();
  useEffect(() => {
    if (sendPendingMerchantData && sendPendingMerchantData.sendPendingMerchantToServer && sendPendingMerchantData.sendPendingMerchantToServer.success == true) {
      console.log("signup data sent to server");
      const merchId = sendPendingMerchantData.sendPendingMerchantToServer.pendingMerchant.merchantId;
      const subId = sendPendingMerchantData.sendPendingMerchantToServer.pendingMerchantSubscription.internalSubscriptionId;
      // setPendingSubId(sendPendingMerchantData.sendPendingMerchantToServer.pendingMerchantSubscription.internalSubscriptionId);
      // Navigate to payment page
      navigate("/intro/signup_select_plan?subid=" + subId + "&merchid=" + merchId);
    }
  }, [navigate, sendPendingMerchantData]);

  const [email, setEmail] = useState("");

  // const registerTrackingDataFunction = () => {
  //   try {
  //     const path = window.location.href;
  //     const deviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? "Mobile" : "Desktop";
  //     registerTrackingMutation({ variables: { path: path, deviceType: deviceType, eventCode: "pageVisit", data: JSON.stringify({ email: email }) } });
  //   } catch (e) {
  //     console.log("fte");
  //   }
  // };

  // TRACKING
  const [registerTrackingMutation, { data: registerTrackingMutationData }] = useMutation(REGISTER_TRACKING_MUTATION);
  useEffect(() => {
    try {
      const path = window.location.href;
      const deviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? "Mobile" : "Desktop";
      registerTrackingMutation({ variables: { path: path, deviceType: deviceType, eventCode: "pageVisit" } });
    } catch (e) {
      console.log("ft");
    }
  }, [registerTrackingMutation]);
  // TRACKING

  const handleChangeEmail = (e: any) => {
    setEmail(e.target.value);
  };

  React.useEffect(() => {
    if (props.capturedEmail != null && props.capturedEmail != undefined) {
      setEmail(props.capturedEmail);
    }
  }, [props.capturedEmail]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Access cookie by name
    const referralCode = Cookies.get("referral_code");
    let referralCodeToSend = "";
    if (referralCode != undefined && referralCode != "") {
      referralCodeToSend = referralCode;
    }

    const data = new FormData(event.currentTarget);
    console.log({
      email: email,
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
      password: data.get("password"),
      phone: data.get("phone"),
      shopifyUrl: data.get("shopify_url"),
      desiredStoreName: data.get("desired_store_name"),
      referralCode: referralCodeToSend,
    });
    try {
      const path = window.location.href;
      const deviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? "Mobile" : "Desktop";
      registerTrackingMutation({
        variables: {
          path: path,
          deviceType: deviceType,
          eventCode: "submitSignUp",
          data: JSON.stringify({
            email: email,
            firstName: data.get("firstName"),
            lastName: data.get("lastNameName"),
            phone: data.get("phone"),
            shopifyUrl: data.get("shopify_url"),
            referralCode: referralCodeToSend,
          }),
        },
      });
    } catch (e) {
      console.log("fte");
    }
    // create pending merchant
    sendPendingMerchantToServer({
      variables: {
        email: email,
        firstName: data.get("firstName"),
        lastName: data.get("lastName"),
        password: data.get("password"),
        phone: data.get("phone"),
        shopifyUrl: data.get("shopify_url"),
        desiredStoreName: data.get("desired_store_name"),
        referralCode: referralCodeToSend,
      },
    });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <div className={styles.header}>
        <h1>Hololoop Shops</h1>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {/* <div className={styles.container}> */}
        <div style={{ marginLeft: "30px" }}>
          {/* <div className={styles["demo-video"]}>
            <iframe title="Holoshop Product Demo" src="https://www.youtube.com/embed/S_QFInMSJiU?autoplay=1&mute=1" frameBorder="0" allowFullScreen></iframe>
          </div> */}

          <div className={styles.section}>
            <h2>Key Features</h2>
            <p>
              With Hololoop Shops, you can create a unique and immersive shopping experience, allowing your customers to interact with your products like never
              before.
            </p>
            <p>Interactive 3D virtual store where customers can explore and shop</p>
            <p>Customer Avatars can try on clothing for a genuine shopping feel</p>
            <p>Multiple Customer Avatars can interact and chat together. This makes shopping fun, immersive and boosts engagement</p>
            <p>Increased sales and brand exposure because your store is a cutting edge 3d shop</p>
            <p>Integrates with Shopify Checkout</p>
            {/* <img src="/images/key_features.png" alt="Key Features" /> */}
          </div>
          {/* <div className={styles.section}>
            <h2>Intuitive Interface</h2>
            <p>Our platform is designed to be easy to use and master, allowing you to focus on creating the best shopping experience for your customers.</p>

          </div> */}

          <div className={styles.section}>
            <h2>Customizable Store Design</h2>
            <p>
              Hololoop Shops allows you complete control over your store's design, enabling you to create a shopping environment tailored to your brand and
              products.
            </p>
            <p>Easily Swap in and out different products and textures.</p>
            <p>Highly recommended for Print On Demand Shops. Simply Upload The Texture of your Apparel.</p>
            <p>Support for custom models</p>
            {/* <img src="/images/customizable_store_design.jpg" alt="Customizable Store Design" /> */}
          </div>
          <div className={styles.section}>
            <h2>Optional Addons Available</h2>
            <p>Custom Storefronts</p>
            <p>We can create Custom Storefronts</p>
            <p>Convert Real Life Brick and Morter Shops to 3D Virtual Stores</p>
            <p>Larger Storefronts</p>
            <p>Custom Features</p>
            {/* <img src="/images/customizable_store_design.jpg" alt="Customizable Store Design" /> */}
          </div>
          <Copyright sx={{ mt: 5 }} />
        </div>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={styles.card}>
            <Box
              sx={{
                // marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign up
              </Typography>
              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField id="firstName" autoComplete="given-name" name="firstName" required fullWidth label="First Name" autoFocus />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField fullWidth id="lastName" label="Last Name" name="lastName" autoComplete="family-name" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      value={email}
                      onChange={handleChangeEmail}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth id="phone" label="Phone Number" name="phone" autoComplete="phone" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth id="desired_store_name" label="Desired Store Name" name="desired_store_name" autoComplete="store_name" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth id="shopify_url" label="Current Shopify URL" name="shopify_url" autoComplete="url" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField required fullWidth id="password" name="password" label="Password" type="password" autoComplete="new_password" />
                  </Grid>
                  {/* <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid> */}
                </Grid>
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                  Sign Up
                </Button>
                {/* <Grid container justifyContent="flex-end">
              <Grid item>
                <Link variant="body2" component="button" onClick={() => props.setSignUpMode(false)}>
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid> */}
              </Box>
            </Box>
          </div>
        </Container>
      </div>
    </ThemeProvider>
  );
}
