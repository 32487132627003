import React, { createContext, useState, useContext } from "react";

interface PopupContextProps {
  value: string | undefined;
  triggerPopup: any;
  clearPopup: any;
}

const PopupContext = createContext<PopupContextProps>({
  value: "",
  triggerPopup: () => {},
  clearPopup: () => {},
});

export const PopupProvider = ({ children }: any) => {
  const [value, setValue] = useState();
  const triggerPopup = (text: React.SetStateAction<undefined>) => setValue(text);
  const clearPopup = (e: any) => setValue(e);

  return <PopupContext.Provider value={{ value, triggerPopup, clearPopup }}>{children}</PopupContext.Provider>;
};
export const usePopup = (): PopupContextProps => useContext(PopupContext);
