import { useState } from "react";
import { Link } from "react-router-dom";
import AboutUsText from "./AboutUsText";
import styles from "./PlayerHeader.module.css";
import ShareButton from "./Sharing";

const MailtoLink = ({ email, subject, body }: any) => {
  const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

  return (
    <Link to={mailtoUrl} className={styles.cta}>
      Contact Us
    </Link>
  );
};

const AboutUs = (props: { closeAboutUs: any }) => {
  return (
    <div
      style={{
        zIndex: "100",
        position: "absolute",
        left: "10px",
        right: "10px",
        top: "10px",
        bottom: "10px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div>
        <div style={{ background: "white", borderRadius: "10px" }}>
          <div style={{ margin: "20px" }}>
            <button className={styles.button} style={{ marginTop: "20px", marginBottom: "20px" }} onClick={() => props.closeAboutUs()}>
              CLOSE
            </button>
            <AboutUsText />
          </div>
        </div>
      </div>
    </div>
  );
};
export function PlayerHeader(props: any) {
  const [displayAboutUs, setDisplayAboutUs] = useState(false);
  const HandleDisplayAboutUs = () => {
    setDisplayAboutUs(true);
  };

  const closeAboutUs = () => {
    setDisplayAboutUs(false);
  };
  return (
    // <div style={{ display: "flex", flexDirection: "row", height: "30px", justifyContent: "flex-start" }}>
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "20px", marginRight: "20px" }}>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "30vw", alignItems: "center", textAlign: "center" }}>
        <div style={{ color: "white" }}>*Hint* Press Esc to see mouse</div>
        <ShareButton copyText={"https://shops.hololoop.co/s/hololoop_hululuup"} />
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", width: "30vw", alignItems: "center", textAlign: "center" }}>
        <h4 onClick={HandleDisplayAboutUs}>About Us Terms Of Service Privacy Policy</h4>
        <MailtoLink email="support@hololoop.co" subject="Customer Help - Hololoop Store" body="I need Support">
          Contact Us
        </MailtoLink>
      </div>
      {displayAboutUs ? <AboutUs closeAboutUs={closeAboutUs} /> : null}
    </div>
  );
}
