import { useMutation } from "@apollo/react-hooks";
import * as React from "react";
import { useEffect } from "react";
import { REGISTER_TRACKING_MUTATION } from "./queries";
import styles from "./SignUpCompletePage.module.css";

export default function SignUpCompletePage() {
  // TRACKING
  const [registerTrackingMutation, { data: registerTrackingMutationData }] = useMutation(REGISTER_TRACKING_MUTATION);
  useEffect(() => {
    try {
      const path = window.location.href;
      const deviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? "Mobile" : "Desktop";
      registerTrackingMutation({ variables: { path: path, deviceType: deviceType, eventCode: "pageVisit" } });
    } catch (e) {
      console.log("ft");
    }
  }, [registerTrackingMutation]);
  // TRACKING

  return (
    <div className={styles["main-container"]}>
      <div className={styles["card-container"]}>
        <h1>Thank You</h1>
        <p>
          We will contact you soon and help you setup your new store. Expect to hear from us in a few days. depending on the difficulty and needs of your store,
          it could take about a week to setup. If you need a custom store built or more customized products, it would take time to build those items in 3D. In
          the meantime, feel free to contact us if you have any questions. At merchant_support.hololoop.co
        </p>
      </div>
    </div>
  );
}
