import gql from "graphql-tag";

export const SEND_OPT_IN_EMAIL_FOR_DEMO = gql`
  mutation SendOptInEmailForDemo($email: String!, $referralCode: String) {
    sendOptInEmailForDemo(input: { email: $email, referralCode: $referralCode }) {
      success
      message
    }
  }
`;

export const SEND_PENDING_MERCHANT_TO_SERVER = gql`
  mutation SendPendingMerchantToServer(
    $email: String!
    $password: String!
    $phone: String
    $desiredStoreName: String
    $shopifyUrl: String
    $firstName: String!
    $lastName: String
    $referralCode: String
  ) {
    sendPendingMerchantToServer(
      input: {
        email: $email
        password: $password
        phone: $phone
        desiredStoreName: $desiredStoreName
        shopifyUrl: $shopifyUrl
        firstName: $firstName
        lastName: $lastName
        referralCode: $referralCode
      }
    ) {
      success
      message
      pendingMerchant {
        username
        merchantId
        desiredStoreName
        merchantStatus
      }
      pendingMerchantSubscription {
        internalSubscriptionId
      }
    }
  }
`;

export const SEND_MERCHANT_BILLING_AGREEMENT_ID_TO_SERVER = gql`
  mutation SendMerchantBillingAgreementIdToServer($merchantId: String!, $paypalBillingAgreementId: String!) {
    sendMerchantBillingAgreementIdToServer(input: { merchantId: $merchantId, paypalBillingAgreementId: $paypalBillingAgreementId }) {
      success
      message
      pendingMerchant {
        username
        merchantId
        desiredStoreName
        merchantStatus
      }
      pendingMerchantSubscription {
        internalSubscriptionId
        paypalBillingAgreementId
      }
    }
  }
`;

export const REGISTER_TRACKING_MUTATION = gql`
  mutation registerTrackingMutation($path: String, $eventCode: String, $deviceType: String, $data: String) {
    registerTrackingMutation(input: { path: $path, eventCode: $eventCode, deviceType: $deviceType, data: $data }) {
      success
      message
    }
  }
`;
