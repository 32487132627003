import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useEffect, useState } from "react";
import { ShoppingCartDataObject } from "./StoreDatatypes";
import { Toaster } from "react-hot-toast";
import toast from "react-hot-toast";
import ShopifyCheckout from "./ShopifyCheckout";
import styles from "./CheckoutPopup.module.css";
const PAYPAL_CLIENT_ID = () => {
  var envprocess = process.env;
  var envprocesslo = process.env.local;
  if (process.env.REACT_APP_PAYPAL_SANDBOX_MODE == "true") {
    return process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT_ID;
  } else if (process.env.REACT_APP_PAYPAL_SANDBOX_MODE == "false") {
    return process.env.REACT_APP_PAYPAL_LIVE_CLIENT_ID;
  }
  return "";
};

export default function CheckoutPopup(props: { closePopup: any; shoppingCartData: string; checkOutCompleteAction: Function }) {
  const [amount, setAmount] = useState("0.00");
  const [paypalEmail, setPaypalEmail] = useState("0.00");
  const [itemName, setItemName] = useState("purchase from a hololoop merchant");
  const [orderId, setOrderId] = useState("");
  const webhookUrl = "https://localhost:8000/u/paypal_webhook";
  const closePopupClicked = () => {
    props.closePopup();
  };

  const [paypalEnabled, setPaypalEnabled] = useState(true);
  const [shopifyEnabled, setShopifyEnabled] = useState(true);

  useEffect(() => {
    let shoppingCartData: ShoppingCartDataObject = JSON.parse(props.shoppingCartData);
    setAmount(shoppingCartData.amount.toString());
    setPaypalEmail(shoppingCartData.paypalEmail);
    setItemName(shoppingCartData.purchaseName);
    setOrderId(shoppingCartData.orderId);
    if (shoppingCartData.paypalEnabled == true) {
      setPaypalEnabled(shoppingCartData.paypalEnabled);
    } else {
      setPaypalEnabled(false);
    }
    // if (shoppingCartData.shopifyEnabled == true) {
    //   setShopifyEnabled(true);
    // } else {
    //   setShopifyEnabled(false);
    // }
    setShopifyEnabled(false);
    console.log("paypalClientId:" + PAYPAL_CLIENT_ID());
    console.log("opening paypal...");
  }, [props.shoppingCartData]);

  // const initialOptions = {
  //   "client-id": "test",
  //   currency: "USD",
  //   intent: "capture",
  //   "data-custom": "123456ABCD"
  // };

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Toaster position="top-center" />
      <div style={{ width: 300, height: 300, display: "flex", justifyContent: "center", borderRadius: "10px", alignItems: "center", background: "white" }}>
        <div style={{ alignItems: "center" }}>
          <div>Amount: ${amount}</div>

          <div>
            {orderId != "null" && orderId != "" ? (
              <div>
                {shopifyEnabled ? <ShopifyCheckout shoppingCartData={props.shoppingCartData} /> : null}

                {paypalEnabled ? (
                  <PayPalScriptProvider options={{ "client-id": PAYPAL_CLIENT_ID() }}>
                    {/* <div
                      style={{ position: "absolute", left: "0", right: "0", top: "0", bottom: "0", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 100 }}
                    ></div> */}
                    <div style={{ zIndex: 110 }}>
                      <PayPalButtons
                        style={{ layout: "horizontal" }}
                        onError={(err) => {
                          toast.error("There was an error processing your payment. If this error please contact support.", { duration: 6000 });
                        }}
                        onApprove={(data, actions) => {
                          if (props.checkOutCompleteAction != null) {
                            try {
                              props.checkOutCompleteAction();
                            } catch (e) {
                              console.log("failed checkout complete action");
                            }
                          }
                          return actions?.order?.capture().then(function (details) {
                            toast.success("Payment completed. Thank you, " + details?.payer?.name?.given_name);
                            props.closePopup();
                          }) as any;
                        }}
                        onCancel={() =>
                          toast("You cancelled the payment. Try again by clicking the PayPal button", {
                            duration: 6000,
                          })
                        }
                        createOrder={(data, actions) => {
                          return actions.order.create({
                            purchase_units: [
                              {
                                amount: {
                                  value: amount,
                                },
                                custom_id: orderId, // the name or slug of the thing you're selling
                              },
                            ],
                          });
                        }}
                      />
                    </div>
                  </PayPalScriptProvider>
                ) : null}
              </div>
            ) : null}
          </div>
          <button className={styles["default-button"]} style={{ position: "relative", right: 0, top: 0 }} onClick={() => closePopupClicked()}>
            closePopup
          </button>
        </div>
      </div>
    </div>
  );
}

// {
/* <form action="https://www.paypal.com/cgi-bin/webscr" method="post">
              <input type="hidden" name="cmd" value="_xclick"></input>
              <input type="hidden" name="business" value={paypalEmail}></input>
              <input type="hidden" name="item_name" value={itemName}></input>
              <input type="hidden" name="amount" value={amount}></input>
              <input type="hidden" name="notify_url" value={webhookUrl}></input>
              <input type="hidden" name="shopping_cart_data" value={props.shoppingCartData}></input>
              <input
                type="image"
                src="https://www.paypalobjects.com/en_US/i/btn/btn_buynow_LG.gif"
                name="submit"
                alt="PayPal - The safer, easier way to pay online!"
              ></input>
            </form> */
// }
