import React from "react";
import styles from "./Spinner.module.css";

const Spinner = (props: { percentage: number }) => {
  return (
    <div className={styles["spinner-container"]}>
      <div className={styles["spinner"]}>{/* Insert your desired spinner animation or image here */}</div>
      <h3 style={{ color: "white" }}>{props.percentage} %</h3>
      <h2 style={{ color: "white" }}>Your 3D Shopping Experience is Loading!</h2>
    </div>
  );
};

export default Spinner;
