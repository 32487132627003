import { PayPalButtons, PayPalScriptProvider, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import React, { useEffect, useState } from "react";

// Define the types for OnApproveData and OnApproveActions
export type OnApproveDataCustom = {
  orderID: string;
  payerID?: string | null | undefined;
  paymentID?: string | null | undefined;
  subscriptionID?: string | null | undefined;
  paymentsource?: string | null | undefined;
  billingToken?: string | null | undefined;
  facilitatorAccessToken: string;
};

type OnApproveActionsCustom = {
  order?: any;
};

const getPayPalClientId = () => {
  var envprocess = process.env;
  var envprocesslo = process.env.local;
  if (process.env.REACT_APP_PAYPAL_SANDBOX_MODE == "true") {
    return process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT_ID;
  } else if (process.env.REACT_APP_PAYPAL_SANDBOX_MODE == "false") {
    return process.env.REACT_APP_PAYPAL_LIVE_CLIENT_ID;
  }
  return "";
};

function PaypalSubscriptionButton(props: {
  subscriptionPlanID: string;
  merchId: string;
  internalSubId: string;
  onApproveCallback?: ((arg0: OnApproveDataCustom) => void) | null | undefined;
}) {
  const [{ options }, dispatch] = usePayPalScriptReducer();

  const createSubscription = (
    data: any,
    actions: {
      subscription: {
        create: (arg0: { plan_id: any; custom: string; custom_id: string }) => Promise<string>;
      };
    }
  ): Promise<string> => {
    console.log("create subscription func!");
    console.log(data);
    console.log(actions);
    return actions.subscription.create({
      plan_id: props.subscriptionPlanID,
      custom: props.internalSubId,
      custom_id: props.internalSubId,
    });
  };

  const onApprove = async (data: OnApproveDataCustom, actions: OnApproveActionsCustom): Promise<void> => {
    // call backend api to store transaction details
    console.log("Paypal approved");
    console.log(data);
    if (props.onApproveCallback != null && props.onApproveCallback != undefined) {
      console.log("in on approve");
      props.onApproveCallback(data);
    } else {
      console.log("no on approve callback");
    }
    // return "Payment approved";
  };

  const [ErrorMessage, setErrorMessage] = useState("");

  const onError = (err: Record<string, unknown>): void => {
    setErrorMessage("An Error occured with your payment ");
  };
  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        intent: "subscription",
      },
    });
  }, [dispatch]);

  return (
    <div style={{ width: "200px" }}>
      <PayPalButtons style={{ layout: "vertical" }} createSubscription={createSubscription} onApprove={onApprove} onError={onError} />
    </div>
  );
}

export default PaypalSubscriptionButton;
